import React from 'react';

import './style/style.scss';
import './style/responsive.style.scss';

import VARIABLES from '../../styles/model-7-1/utils/_variables.scss';


class ImageSlider1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImageIndex: 0,
            slide: false,
        }

        this.timeOut = null;
        this.VAR = VARIABLES;
    }

    componentDidMount() {
        window.onload = this._startSlide();
    }

    componentWillUnmount() {
        this._stopSlide();
    }

    _startSlide() {
        this.timeOut = setInterval(() => {
            this.state.currentImageIndex < this.props.imageList.length - 1  ?
                this._changeCurrentImage( this.state.currentImageIndex + 1 ) :
                this._changeCurrentImage(0)
        }, this.props.delay);
    }

    _stopSlide() {
        clearInterval( this.timeOut );
    }

    _changeCurrentImage( newImageIndex ) {
        // console.log( newImageIndex );
        this.setState({
            slide: true,
        });
        setTimeout(() => {
            if( newImageIndex > this.state.currentImageIndex ) {
                for( let i =  this.state.currentImageIndex; i < newImageIndex ; i++ ) {
                    let current = document.getElementById( "image" + i );
                    current.style.marginLeft = "-100%" ;
                }
            }

            else if( newImageIndex < this.state.currentImageIndex ) {
                for( let i =  this.state.currentImageIndex; i >= newImageIndex ; i-- ) {
                    let current = document.getElementById( "image" + i );
                    current.style.marginLeft = "0%" ;
                }
                // let current = document.getElementById( "image" + newImageIndex );
                // current.style.marginLeft = "0%" ;
            }

            this.setState({
                currentImageIndex: newImageIndex,
                slide: false,
            }, () => {
                this._stopSlide();
                this._startSlide();
            });
        }, 250);
    }

    render() {
        return (
            <div className="imageSlider1_container">
                <div className="imageSlider1_container_">
                    <div className="imageSlider1_container_images">
                        {
                            this.props.imageList.map((image, index) => (
                                <div
                                    className="imageSlider1_container_images_"
                                    id={"image" + index }
                                    key={index}
                                >
                                    <img
                                        src={ "upload/images/" + image.src }
                                        alt=""
                                        className="imageSlider1_container_images_img"
                                    />
                                </div>
                            ))
                        }
                    </div>
                    
                </div>
                <div className="imageSlider1_container_controller">
                    <div className="imageSlider1_container_controller_">
                        {
                            // this.props.imageList.map((image, index) => (
                            //     <div
                            //         className="dot1"
                            //         key={index}
                            //         onClick={() => {
                            //             this._changeCurrentImage( index );
                            //         }}
                            //         style={{
                            //             backgroundColor: this.state.currentImageIndex === index ? "#dc3545" : "#ffffff",
                            //             color: this.state.currentImageIndex === index ? "#ffffff" : "#dc3545",
                            //             transform: this.state.currentImageIndex === index ? "scale(0.8)" : "scale(1)",
                            //         }}
                            //     >
                            //         <span>
                            //             {index + 1}
                            //         </span>
                            //     </div>
                            // ))
                        }
                    </div>
                </div>
                {this.props.anotherContent()}
            </div>

        )
    }
}

export default ImageSlider1;