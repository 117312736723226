import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import '../styles/dashboard.prescription.style.scss';

import ChatRequest from '../helpers/chat.requests.helper';

import loader from '../assets/gif/loader.gif';

import { saveAsPDF } from '../helpers/utils';

const chat_ = new ChatRequest();

const currentUser = JSON.parse(localStorage.getItem("ilera"));

var currentAction = "add";

function DashboardDoctorPrescription( props ) {
    const [isLoading, setIsLoading] = useState(false);
    const [patientsList, setPatientsList] = useState([]);
    const [prescriptionList, setPrescriptionList] = useState([]);
    const [current_prescription, setCurrent_prescription] = useState(null);
    const [addNew_prescription, setAddnew_prescription] = useState(false);
    const [sent_data, setSent_data] = useState({});
    const [new_prescription, setNew_prescription] = useState({
        name: "",
        picture: "",
        ID: "",
        phone_number: "",
        gender: "",
        date: "",
        time: "",
        patient: {
            name : "",
            born: "",
            age: "",
            address: "",
            id_: "",
            weight: "",
            taille: "",
        },
        doctor: {
            name: "",
            surname: "",
        },
        hospital: {
            name: "",
            address: "",
        },
        history: [],
        diagnosis: [],
        prescription: [],
        instructions: [],
        followUp: [],
        chief_complaint: [],
    });

    function getUserContacted() {
        setIsLoading(true);
        chat_.GetUsersContacted().then((res)=>{
            setIsLoading(false);
            setPatientsList(res.data.mes_patients);
            console.log(res.data);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function getUserPrescriptionsList() {
        setIsLoading(true);
        chat_.GetUsersPrescriptionsList().then((res)=>{
            setIsLoading(false);
            setPrescriptionList(res.data.all_prescription_list);
            console.log(res.data);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function _createPrescription() {
        let prescription = {
            "patient": {
                "id": document.getElementById("currentPatient").value,
                "weight": document.getElementById("patient_weight").value,
                "taille": document.getElementById("patient_height").value,
            },
            "hospital": {
                "nom": document.getElementById("hospital_name").value,
                "address": document.getElementById("hospital_address").value
            },
            "Fait constaté & évoquer par le patient": new_prescription.history,
            "diagnoscstic": new_prescription.diagnosis,
            "prescription": new_prescription.prescription,
            "instructions": new_prescription.instructions,
            "followUp": new_prescription.followUp,
            "chief_complaint": new_prescription.history
        }

        setIsLoading(true);
        chat_.SendPrescription(prescription).then((res)=>{
            setIsLoading(false);
            alert("Valider");
            if(res.data.success) {
                window.location.reload();
            }
            console.log(res.data);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function _updatePrescription() {
        let prescription = {
            prescription_content : {
                "patient": {
                    "id": document.getElementById("currentPatient").value,
                    "weight": document.getElementById("patient_weight").value!=="" ? document.getElementById("patient_weight").value : new_prescription.patient.weight,
                    "taille": document.getElementById("patient_height").value!=="" ? document.getElementById("patient_height").value : new_prescription.patient.taille,
                },
                "hospital": {
                    "nom": document.getElementById("hospital_name").value!=="" ? document.getElementById("hospital_name").value : new_prescription.hospital.name,
                    "address": document.getElementById("hospital_address").value!=="" ? document.getElementById("hospital_address").value : new_prescription.hospital.address,
                },
                "Fait constaté & évoquer par le patient": new_prescription.history,
                "diagnoscstic": new_prescription.diagnosis,
                "prescription": new_prescription.prescription,
                "instructions": new_prescription.instructions,
                "followUp": new_prescription.followUp,
                "chief_complaint": new_prescription.history
            }
        }

        console.log(prescription);
        setIsLoading(true);
        chat_.UpdatePrescription(new_prescription.prescription_id, prescription).then((res)=>{
            setIsLoading(false);
            alert("Modification Validée");
            if(res.data.status === "success") {
                window.location.reload();
            }
            console.log(res.data);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function _deletePrescription(id) {
        setIsLoading(true);
        chat_.deletePrescription(id).then((res)=>{
            setIsLoading(false);
            if(res.data.status === "success") {
                window.location.reload();
            }
            console.log(res.data);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function getOnePrescription(id) {
        setIsLoading(true);
        chat_.GetOnePrescription(id).then((res)=>{
            setIsLoading(false);
            let pres = res.data.updated_prescription ? JSON.parse(res.data.updated_prescription.replace(/'/g, '"')) : res.data.sent_data;
            console.log(pres);
            if(currentAction==="add") {
                setCurrent_prescription(pres);
                setSent_data(res.data.sent_data);
            } else {
                setAddnew_prescription(true);
                setNew_prescription({
                    prescription_id: id,
                    name: "",
                    picture: "",
                    ID: pres.patient.id,
                    phone_number: "",
                    gender: "",
                    date: "",
                    time: "",
                    patient: {
                        name : "",
                        born: "",
                        age: "",
                        address: "",
                        id_: "",
                        weight: pres.patient.weight,
                        taille: pres.patient.taille,
                    },
                    doctor: {
                        name: "",
                        surname: "",
                    },
                    medecin: {
                        nom: "",
                        prenom: "",
                    },
                    hospital: {
                        name: pres.hospital.nom,
                        address: pres.hospital.address,
                    },
                    history: pres["Fait constaté & évoquer par le patient"],
                    diagnosis: pres.diagnoscstic,
                    prescription: pres.prescription,
                    instructions: pres.instructions,
                    followUp: pres.followUp,
                    chief_complaint: pres.chief_complaint,
                });

                console.log(res.data);
            }
            
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getUserContacted();
        getUserPrescriptionsList();
    },[]);
        
    return (
        <div className='prescription'>
            <div className="prescription_list">
                <div className='prescription_list_head'>
                    <div className='prescription_list_head_left'>
                        <div className='prescription_list_head_left_content'>
                            <span>Filtrer par </span>
                            <select>
                                <option>
                                    Date
                                </option>
                                <option>
                                    Nom
                                </option>
                                <option>
                                    Genre
                                </option>
                                <option>
                                    ID
                                </option>
                            </select> 
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className='prescription_list_head_left'>
                        <div className='prescription_list_head_left_content'>
                            <select>
                                <option>
                                    20
                                </option>
                                <option>
                                    50
                                </option>
                                <option>
                                    80
                                </option>
                                <option>
                                    120
                                </option>
                                <option>
                                    200
                                </option>
                                <option>
                                    500
                                </option>
                                <option>
                                    Tout
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className='prescription_list_head_right'>
                        <div className='prescription_list_head_right_content'>
                            <input type='search' placeholder='Rechercher...' />
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="prescription_list_title">
                    <span>Liste des ordonnances</span>
                    <div className="prescription_list_title_export">
                        <button>
                            <span>PDF</span>

                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                <g fill-rule="evenodd">
                                    <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                                    <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
                                </g>
                            </svg>
                        </button>
                        <button>
                            <span>Excel</span>

                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM19 12C19.5523 12 20 12.4477 20 13V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H19C18.4477 21 18 20.5523 18 20V13C18 12.4477 18.4477 12 19 12ZM11.8137 12.4188C11.4927 11.9693 10.8682 11.8653 10.4188 12.1863C9.96935 12.5073 9.86526 13.1318 10.1863 13.5812L12.2711 16.5L10.1863 19.4188C9.86526 19.8682 9.96935 20.4927 10.4188 20.8137C10.8682 21.1347 11.4927 21.0307 11.8137 20.5812L13.5 18.2205L15.1863 20.5812C15.5073 21.0307 16.1318 21.1347 16.5812 20.8137C17.0307 20.4927 17.1347 19.8682 16.8137 19.4188L14.7289 16.5L16.8137 13.5812C17.1347 13.1318 17.0307 12.5073 16.5812 12.1863C16.1318 11.8653 15.5073 11.9693 15.1863 12.4188L13.5 14.7795L11.8137 12.4188Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                    <a href='##'
                        onClick={(e)=>{
                            e.preventDefault();
                            currentAction = "add";
                            setAddnew_prescription(true);
                        }}
                    >
                        + Ajouter une ordonnance
                    </a>
                </div>
                
                <div className="prescription_list_content">
                <table className='table-classic'>
                        <thead>
                            <tr>
                                {/* <th>Photo</th> */}
                                <th>ID</th>
                                <th>Nom du patient</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                prescriptionList.map((prescription, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        {/* <td><img src={prescription.picture} alt='ilera'/></td> */}
                                        <td>{prescription.prescription_ID}</td>
                                        <td>{prescription.prescription_receiver}</td>
                                        <td>{prescription.date_envoie}</td>
                                        <td>
                                            <button className='view'
                                                onClick={(e)=>{
                                                    getOnePrescription(prescription.prescription_ID);
                                                }}
                                            >
                                                Voir
                                            </button>

                                            <button className='update'
                                                onClick={(e)=>{
                                                    currentAction = "update";
                                                    getOnePrescription(prescription.prescription_ID);
                                                }}
                                            >
                                                Modifier
                                            </button>

                                            <button className='delete'
                                                onClick={(e)=>{
                                                    if(window.confirm("Voulez-vous vraiment supprimer?")) {
                                                        _deletePrescription(prescription.prescription_ID);
                                                    }
                                                    
                                                }}
                                            >
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {
                addNew_prescription ?
                    <section className='information'>
                        <div className='information_content'>
                                <div className='information_content_close'>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        setAddnew_prescription(false);
                                    }}>
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                            <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="information_content_head">
                                    <div className="information_content_head_date">
                                        Date: {new_prescription.date} à {new_prescription.time}
                                    </div>
                                    <div className="information_content_head_id">
                                        ID du patient: {new_prescription.patient.id_}, ID consultation: {new_prescription.appointmentId}
                                    </div>
                                    <div className="information_content_head_history">
                                        <a href="##">Historique</a>
                                    </div>
                                </div>
                                
                                <div className="information_content_body">
                                    <div className="information_content_body_head">
                                        <div className="information_content_body_head_dr">
                                            Dr {currentUser.nom}
                                        </div>

                                        {/* <div className="information_content_body_head_hospital">
                                            <div className="information_content_body_head_hospital_name">
                                                {new_prescription.hospital.name}
                                            </div>
                                            <div className="information_content_body_head_hospital_address">
                                                {new_prescription.hospital.address}
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Nom de l'hôpital : ({new_prescription.hospital.name})
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='hospital_name' name='' placeholder='Ex: Clinique xxx' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Adresse de l'hôpital : ({new_prescription.hospital.address})
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='hospital_address' name='' placeholder='Ex: Rue xxx, en face de ....' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="information_content_body_patient">
                                        <b>Nom du patient: </b>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <select id='currentPatient'>
                                                        {
                                                            patientsList.map((patient)=>(
                                                                <option value={patient.ID}>
                                                                    {patient.nom} {patient.prenom}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='information_content_body_cc_title'>
                                                    Taille du patient (cm) : {new_prescription.patient.taille}
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id='patient_height' name='' placeholder='Ex: 196' type='number'/>
                                                </div>
                                            </div>
                                            <div className='form_input'>
                                                <div className='information_content_body_cc_title'>
                                                    Poids du patient (kg) : {new_prescription.patient.weight}
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id='patient_weight' name='' placeholder='Ex: 90' type='number'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Plainte(s) principale(s): {new_prescription.chief_complaint.length}
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='chief_complaint' name='' placeholder='Ex: Mal de ventre' type='text'/>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={(e)=>{
                                                        let chief = document.getElementById("chief_complaint").value;
                                                        if( chief !== "" ) {
                                                            let np = new_prescription.chief_complaint;
                                                            np.push(chief);
                                                            setNew_prescription((prev)=>{
                                                                document.getElementById("chief_complaint").value = "";
                                                                return{
                                                                    ...prev,
                                                                    chief_complaint: np,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <ul>
                                            {new_prescription.chief_complaint.map((cc, index) => (
                                                <li>
                                                    - {cc} <button className="delete"
                                                        onClick={(e)=>{
                                                            let np = new_prescription.chief_complaint;
                                                            np.splice(index, 1);
                                                            setNew_prescription((prev)=>{
                                                                return{
                                                                    ...prev,
                                                                    chief_complaint: np,
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                            <g>
                                                                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Historique médical: {new_prescription.history.length}
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='history' name='' placeholder="Ex: Pas d'allergies connues à des médicaments." type='text'/>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={(e)=>{
                                                        let history = document.getElementById("history").value;
                                                        if( history !== "" ) {
                                                            let np = new_prescription.history;
                                                            np.push(history);
                                                            setNew_prescription((prev)=>{
                                                                document.getElementById("history").value = "";
                                                                return{
                                                                    ...prev,
                                                                    history: np,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <ul>
                                            {new_prescription.history.map((history, index) => (
                                                <li>
                                                    - {history} <button className="delete"
                                                        onClick={(e)=>{
                                                            let np = new_prescription.history;
                                                            np.splice(index, 1);
                                                            setNew_prescription((prev)=>{
                                                                return{
                                                                    ...prev,
                                                                    history: np,
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                            <g>
                                                                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Diagnostic: {new_prescription.diagnosis.length}
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='diagnosis' name='' placeholder="Ex:  Recommandation pour un traitement symptomatique." type='text'/>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={(e)=>{
                                                        let diagnosis = document.getElementById("diagnosis").value;
                                                        if( diagnosis !== "" ) {
                                                            let np = new_prescription.diagnosis;
                                                            np.push(diagnosis);
                                                            setNew_prescription((prev)=>{
                                                                document.getElementById("diagnosis").value = "";
                                                                return{
                                                                    ...prev,
                                                                    diagnosis: np,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <ul>
                                            {new_prescription.diagnosis.map((dgs, index) => (
                                                <li>
                                                    - {dgs} <button className="delete"
                                                        onClick={(e)=>{
                                                            let np = new_prescription.diagnosis;
                                                            np.splice(index, 1);
                                                            setNew_prescription((prev)=>{
                                                                return{
                                                                    ...prev,
                                                                    diagnosis: np,
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                            <g>
                                                                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Ordonnance: 
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_title'>
                                                    <span>Nom</span>
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id='od_name' name='' placeholder='nom' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_title'>
                                                    <span>Mg/Ml	</span>
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id="od_MgMl" name='' placeholder='' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_title'>
                                                    <span>Dose</span>
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id="od_dose" name='' placeholder='' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_title'>
                                                    <span>Fréquence</span>
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id="od_frequence" name='' placeholder='' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_title'>
                                                    <span>Commentaire</span>
                                                </div>
                                                <div className='form_input_balise'>
                                                    <input id="od_comment" name='' placeholder='' type='text'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div>
                                                <button
                                                    onClick={(e)=>{
                                                        let prescription = {
                                                            "medicine_name" : document.getElementById("od_name").value,
                                                            "mg_ml" : document.getElementById("od_MgMl").value,
                                                            "dose" : document.getElementById("od_dose").value,
                                                            "interval" : document.getElementById("od_frequence").value,
                                                            "comment" : document.getElementById("od_comment").value
                                                        }

                                                        let np = new_prescription.prescription;
                                                        np.push(prescription);
                                                        setNew_prescription((prev)=>{
                                                            document.getElementById("od_name").value = "";
                                                            document.getElementById("od_MgMl").value = "";
                                                            document.getElementById("od_dose").value = "";
                                                            document.getElementById("od_frequence").value = "";
                                                            document.getElementById("od_comment").value = "";
                                                            return{
                                                                ...prev,
                                                                prescription: np,
                                                            }
                                                        });
                                                    }}
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <table className='table-classic'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Nom
                                                    </th>
                                                    <th>
                                                        Mg/Ml
                                                    </th>
                                                    <th>
                                                        Dose
                                                    </th>
                                                    <th>
                                                        Fréquence
                                                    </th>
                                                    <th>
                                                        Commentaire
                                                    </th>
                                                    <th>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    new_prescription.prescription.map((pres, index) => (
                                                        <tr>
                                                            <td>
                                                                {pres.medicine_name}
                                                            </td>
                                                            <td>
                                                                {pres.mg_ml}
                                                            </td>
                                                            <td>
                                                                {pres.dose}
                                                            </td>
                                                            <td>
                                                                {pres.interval}
                                                            </td>
                                                            <td>
                                                                {pres.comment}
                                                            </td>
                                                            <td>
                                                                <button className="delete"
                                                                    onClick={(e)=>{
                                                                        let np = new_prescription.prescription;
                                                                        np.splice(index, 1);
                                                                        setNew_prescription((prev)=>{
                                                                            return{
                                                                                ...prev,
                                                                                prescription: np,
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                                        <g>
                                                                            <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Consignes: {new_prescription.instructions.length}
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='instructions' name='' placeholder="Ex: Éviter..." type='text'/>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={(e)=>{
                                                        let instructions = document.getElementById("instructions").value;
                                                        if( instructions !== "" ) {
                                                            let np = new_prescription.instructions;
                                                            np.push(instructions);
                                                            setNew_prescription((prev)=>{
                                                                document.getElementById("instructions").value = "";
                                                                return{
                                                                    ...prev,
                                                                    instructions: np,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <ul>
                                            {new_prescription.instructions.map((instruction, index) => (
                                                <li>
                                                    - {instruction} <button className="delete"
                                                        onClick={(e)=>{
                                                            let np = new_prescription.instructions;
                                                            np.splice(index, 1);
                                                            setNew_prescription((prev)=>{
                                                                return{
                                                                    ...prev,
                                                                    instructions: np,
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                            <g>
                                                                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className='information_content_body_cc'>
                                        <div className='information_content_body_cc_title'>
                                            Suivi: 
                                        </div>
                                        <div className='flex'>
                                            <div className='form_input'>
                                                <div className='form_input_balise'>
                                                    <input id='followUp' name='' placeholder="" type='text'/>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={(e)=>{
                                                        let followUp = document.getElementById("followUp").value;
                                                        if( followUp !== "" ) {
                                                            let np = new_prescription.followUp;
                                                            np.push(followUp);
                                                            setNew_prescription((prev)=>{
                                                                document.getElementById("followUp").value = "";
                                                                return{
                                                                    ...prev,
                                                                    followUp: np,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <ul>
                                            {new_prescription.followUp.map((followUp, index) => (
                                                <li>
                                                    - {followUp} <button className="delete"
                                                        onClick={(e)=>{
                                                            let np = new_prescription.followUp;
                                                            np.splice(index, 1);
                                                            setNew_prescription((prev)=>{
                                                                return{
                                                                    ...prev,
                                                                    followUp: np,
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                            <g>
                                                                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className='information_content_foot'>
                                    <span className='information_content_foot_signature'>Signature</span>
                                    <span>------------------</span>
                                </div>

                                <br/>
                                <div className='form_button'
                                    onClick={(e)=>{
                                    console.log(currentAction);
                                        if(currentAction==="add") {
                                            _createPrescription();
                                        } else {
                                            _updatePrescription()
                                        }
                                    }}
                                >
                                    <button>
                                        Valider l'ordonnance
                                    </button>
                                </div>
                            </div>
                    </section>
                :
                    null
            }

            {
                    current_prescription !== null ?
                        <section className='information'>
                            <div className='information_content'>
                                <div className='information_content_close'>
                                    <button onClick={(e) => {
                                        setCurrent_prescription(null);
                                    }}>
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                            <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="information_content_head">
                                    <div className="prescription_list_title_export">
                                        <button
                                            onClick={(e)=>{
                                                setIsLoading(true);
                                                saveAsPDF("ordonnance", ()=>{
                                                    setIsLoading(false);
                                                });
                                            }}
                                        >
                                            <span>PDF</span>

                                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                                <g fillRule="evenodd">
                                                    <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                                                    <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
                                                </g>
                                            </svg>
                                        </button>
                                        <button>
                                            <span>Excel</span>

                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM19 12C19.5523 12 20 12.4477 20 13V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H19C18.4477 21 18 20.5523 18 20V13C18 12.4477 18.4477 12 19 12ZM11.8137 12.4188C11.4927 11.9693 10.8682 11.8653 10.4188 12.1863C9.96935 12.5073 9.86526 13.1318 10.1863 13.5812L12.2711 16.5L10.1863 19.4188C9.86526 19.8682 9.96935 20.4927 10.4188 20.8137C10.8682 21.1347 11.4927 21.0307 11.8137 20.5812L13.5 18.2205L15.1863 20.5812C15.5073 21.0307 16.1318 21.1347 16.5812 20.8137C17.0307 20.4927 17.1347 19.8682 16.8137 19.4188L14.7289 16.5L16.8137 13.5812C17.1347 13.1318 17.0307 12.5073 16.5812 12.1863C16.1318 11.8653 15.5073 11.9693 15.1863 12.4188L13.5 14.7795L11.8137 12.4188Z" fill="currentColor"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div id='ordonnance'>
                                    <div className="information_content_head">
                                        <div className="information_content_head_date">
                                            {/* Date: {current_prescription.date} à {current_prescription.time} */}
                                        </div>
                                        <div className="information_content_head_id">
                                            {/* ID du patient: {current_prescription.patient.id_}, ID consultation: {current_prescription.appointmentId} */}
                                        </div>
                                        <div className="information_content_head_history">
                                            <a href="##">Historique</a>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="information_content_body">
                                        <p>
                                            {current_prescription}
                                        </p>
                                    </div> */}
                                    
                                    <div className="information_content_body">
                                        <div className="information_content_body_head">
                                            <div className="information_content_body_head_dr">
                                                Dr {sent_data.medecin.nom} {sent_data.medecin.prenom}
                                            </div>

                                            <div className="information_content_body_head_hospital">
                                                <div className="information_content_body_head_hospital_name">
                                                    {current_prescription.hospital.nom}
                                                </div>
                                                <div className="information_content_body_head_hospital_address">
                                                    {current_prescription.hospital.address}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="information_content_body_patient">
                                            {/* <b>Nom du patient: </b><b className='patient_name'>{current_prescription.patient.nom} {current_prescription.patient.prenom}</b>, <b>Âge: </b> {current_prescription.patient.age}, <b>Genre: </b> {current_prescription.gender}, <b>Poids: </b> {current_prescription.patient.weight} */}
                                            <b>Nom du patient: </b><b className='patient_name'>{current_prescription.patient.nom} {current_prescription.patient.prenom}</b>, <b>Taille: </b> {current_prescription.patient.taille}, <b>Poids: </b> {current_prescription.patient.weight}
                                        </div>

                                        <div className='information_content_body_cc'>
                                            <div className='information_content_body_cc_title'>
                                                Plainte(s) principale(s):
                                            </div>
                                            <ul>
                                                {current_prescription["Fait constaté & évoquer par le patient"].map((cc, index) => (
                                                    <li>
                                                        - {cc}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className='information_content_body_cc'>
                                            <div className='information_content_body_cc_title'>
                                                Historique médical:
                                            </div>
                                            <ul>
                                                {current_prescription.chief_complaint.map((history, index) => (
                                                    <li>
                                                        - {history}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className='information_content_body_cc'>
                                            <div className='information_content_body_cc_title'>
                                                Diagnostic: 
                                            </div>
                                            <ul>
                                                {current_prescription.diagnoscstic.map((dgs, index) => (
                                                    <li>
                                                        - {dgs}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className='information_content_body_cc'>
                                            <div className='information_content_body_cc_title'>
                                                Ordonnance: 
                                            </div>
                                            <table className='table-classic'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Nom
                                                        </th>
                                                        <th>
                                                            Mg/Ml
                                                        </th>
                                                        <th>
                                                            Dose
                                                        </th>
                                                        <th>
                                                            Fréquence
                                                        </th>
                                                        <th>
                                                            Commentaire
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        current_prescription.prescription.map((pres, index) => (
                                                            <tr>
                                                                <td>
                                                                    {pres.medicine_name}
                                                                </td>
                                                                <td>
                                                                    {pres.mg_ml}
                                                                </td>
                                                                <td>
                                                                    {pres.dose}
                                                                </td>
                                                                <td>
                                                                    {pres.interval}
                                                                </td>
                                                                <td>
                                                                    {pres.comment}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='information_content_body_cc'>
                                            <div className='information_content_body_cc_title'>
                                                Consignes: 
                                            </div>
                                            <ul>
                                                {current_prescription.instructions.map((instruction, index) => (
                                                    <li>
                                                        - {instruction}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className='information_content_body_cc'>
                                            <div className='information_content_body_cc_title'>
                                                Suivi: 
                                            </div>
                                            <ul>
                                                {current_prescription.followUp.map((followUp, index) => (
                                                    <li>
                                                        - {followUp}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='information_content_foot'>
                                        <span className='information_content_foot_signature'>Signature</span>
                                        <span>------------------</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    :
                        null
                }
            
            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDoctorPrescription);