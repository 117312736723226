import React from 'react';
import { connect } from 'react-redux';

import 'react-chat-elements/dist/main.css';
import { ChatList } from 'react-chat-elements';
import { Input } from 'react-chat-elements';
import { MessageList } from 'react-chat-elements';
import { Button } from 'react-chat-elements';



import '../styles/dashboard.messages.style.scss';

import img1 from '../assets/images/people/Example13.jpg';
import img3 from '../assets/images/people/Example11.jpg';
import img2 from '../assets/images/illustration-de-l-infographie-sur-l-anatomie-du-corps-humain-2dckdw0.jpg';
import img4 from '../assets/images/people/Example17.jpg';
import img5 from '../assets/images/people/Example19.jpg';
import img6 from '../assets/images/people/Example15.jpg';
import img7 from '../assets/images/people/E.jpg';

import video from '../assets/videos/video.mp4';
import audio from '../assets/audio/audio.mp3'

const inputReferance = React.createRef();
const messageListReferance = React.createRef();

function DashboardDoctorMessages( props ) {
        
    return (
        <div className='messages'>
            <div className='messages_content'>
                <div className='messages_content_chatList'>
                    <ChatList
                        className='chat-list'
                        dataSource={[
                            {
                                avatar: img1,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            },{
                                avatar: img2,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            },{
                                avatar: img3,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            },{
                                avatar: img4,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            },{
                                avatar: img5,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            },{
                                avatar: img6,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            },{
                                avatar: img7,
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 1,
                            }
                        ]}
                    />
                </div>
                <div className='messages_content_right'>
                    <div className='messages_content_right_messageList'>
                        <MessageList
                            referance={messageListReferance}
                            className='message-list'
                            lockable={true}
                            toBottomHeight={'100%'}
                            messageBoxStyles={{
                                marginBottom: "20px",
                                boxShadow: "1px 1px 5px 1px #00000033",
                                objectFit: "contain",
                                backgroundSize: "contain",
                            }}
                            dataSource={[
                                {
                                    position: 'right',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                },{
                                    position: 'left',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                },{
                                    position: 'right',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                },{
                                    position: 'left',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                    status:"waiting",
                                },{
                                    position: 'right',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                },{
                                    position: 'left',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                    forwarded:true,
                                    replyButton:true,
                                    status:"sent",
                                },{
                                    position: 'right',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                    forwarded:true,
                                    replyButton:true,
                                },{
                                    position: 'left',
                                    type: 'photo',
                                    data:{
                                        uri: img2,
                                        status: {
                                          click: true,
                                          loading: 0,
                                        }
                                    },
                                    date: new Date(),
                                },{
                                    position: 'right',
                                    type: 'video',
                                    data:{
                                        uri: video,
                                        status: {
                                          click: true,
                                          loading: 0,
                                        }
                                    },
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                    forwarded:true,
                                    replyButton:true,
                                    status:"received",
                                },{
                                    position: 'right',
                                    type: 'audio',
                                    data:{
                                        uri: audio,
                                        status: {
                                          click: true,
                                          loading: 0,
                                        }
                                    },
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                    forwarded:true,
                                    replyButton:true,
                                    status:"received",
                                },{
                                    position: 'left',
                                    type: 'text',
                                    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                    date: new Date(),
                                    status:"read",
                                    retracted: true
                                }
                            ]}
                        />
                    </div>
                    <div className='messages_content_right_input'>
                        <Input
                            referance={inputReferance}
                            placeholder='Votre méssage...'
                            multiline={true}
                            value={""}
                            inputStyle={{
                                width: "100%",
                                border: "1px solid #00000022",
                                padding: "10px",
                                color: "#000000aa"

                            }}
                            rightButtons={<Button color='white' backgroundColor='black' text='Envoyer' />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDoctorMessages);