import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import '../styles/paymentView.screen.style.scss';
// import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';
// import 'react-credit-cards-2/dist/es/styles-compiled.css';

import loader from '../assets/gif/loader.gif';

import UserRequest from '../helpers/user.requests.helper';
const USR = new UserRequest();

function PaymentsView( props ) {    
    // const PUBLIC_KEY = 'pk_sandbox_Nu-qxGL6QyhIjshCieh_1ALv';
    const [viewMatchingResult, setViewMatchingResult] = useState(false);
    const [userMatching, setUserMatching] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const speciality = searchParams.get('speciality');
    // const coast = searchParams.get('coast');
    const currentUserRequest = JSON.parse(localStorage.getItem("ilera-patient-request"));
    const currentUserRequestDoctor = JSON.parse(localStorage.getItem("ilera-patient-requestDoctor"));

    const currentUser = JSON.parse(localStorage.getItem("ilera"));


    function selectMatching() {
        setIsLoading(true);
        let data = {
            paiement_effectue: "succes",
            medecin_choisi: currentUserRequestDoctor.username,
            rdv_id: currentUserRequest.rendezvous_id,
            conversation_id: currentUserRequest.conversation_id,
            user_id: currentUser.ID,
        }
        console.log(currentUserRequestDoctor);
        USR.selectMatchingAndSendToDoctor(data).then((response) => {
            setIsLoading(false);
            if(response.data.success === true) {
                alert("Votre demande a bien été prise en compte.");
                window.location.href = "/";
            } else {
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        selectMatching();
    },[setIsLoading]);

    return (
        <div className='payment'>
            {/* <section className="page-header">
                <div className="particles-snow" id="header-snow"></div>
                <div className="container text-center payment_container">
                    <FedaCheckoutButton 
                        options={ checkoutButtonOptions }
                    />
                    <FedaCheckoutContainer
                        options={ checkoutEmbedOptions }
                        style={{
                            height: "600px",
                            width: "100%",
                            maxWidth: "700px",
                            borderRadius: "7px",
                            backgroundColor: '#ffffff',
                            marginTop: "20px",
                        }} 
                    />
                </div>
            </section> */}

            {
                !viewMatchingResult && userMatching !== null?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setViewMatchingResult(false);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        {userMatching.message ? userMatching.message : "Selectionnez un rendez-vous!"}
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <section className='table_content'>
                                    <div>
                                        {userMatching.medecins && userMatching.medecins.length + " resultat(s)"}
                                    </div>
                                    <table class="table table--bordered" align="center">
                                        <caption class="table__title"></caption>
                                        <thead class="table--secondary">
                                            <tr>
                                                <th>Médecins spécialistes</th>
                                                <th>Spécialité</th>
                                                <th>Numéro de téléphone</th>
                                                <th>Adresse</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                userMatching.medecins &&
                                                userMatching.medecins.map((medecin, index) => (
                                                    <tr class="cell--center">
                                                        <th>{medecin.nom + " " + medecin.prenom}</th>
                                                        <td>{medecin.specialite}</td>
                                                        <td>{medecin.numero_telephone}</td>
                                                        <td>{medecin.adresse}</td>
                                                        <td>
                                                            <button
                                                                className='tab-button-1'
                                                                onClick={(e) => {
                                                                    console.log(medecin);
                                                                    selectMatching(medecin.username);
                                                                }}
                                                            >
                                                                Choisir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </section>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsView);