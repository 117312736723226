import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle';

/**Importation des images */
// import stn from '../assets/images/sante-bien-etre-png-175.png';

function ContactIlera( props ) {
    const contenuHTML = `
    <section class="banner-one">
        <div class="particles-snow" id="header-snow"></div>
        <!-- /#header-snow.particles-snow -->
        <div class="container text-center">
            <div class="row">
                <div class="col-xl-5">
                    <div class="contact-two__info">
                        <h3 class="text-white">Nos Contacts</h3>
                        <div class="contact-two__info-single">
                            <div class="contact-two__info-icon">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <div class="contact-two__info-content">
                                <!-- <h4 class="text-white">Localisation</h4> -->
                                <p class="text-white">Lot: 2019 - Maison BOSSOU Emmanuel<br> Zogbohouè - Cotonou</p>
                            </div>
                        </div>
                        <div class="contact-two__info-single">
                            <div class="contact-two__info-icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <!-- /.contact-two__info-icon -->
                            <div class="contact-two__info-content">
                                <!-- <h4 class="text-white">Numéro de Téléphone</h4> -->
                                <p class="text-white"><a href="">(229) 97 58 27 87</a></p>
                            </div>
                            <!-- /.contact-two__info-content -->
                        </div>
                        <!-- /.contact-two__info-single -->
                        <div class="contact-two__info-single">
                            <div class="contact-two__info-icon">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <!-- /.contact-two__info-icon -->
                            <div class="contact-two__info-content">
                                <!-- <h4 class="text-white">Emails</h4> -->
                                <p class="text-white"><a href="">contact@ilera.tech</a>
                                </p>
                            </div>
                            <!-- /.contact-two__info-content -->
                        </div>
                        <!-- /.contact-two__info-single -->
                    </div>
                    <!-- /.contact-two__info -->
                    <div class="contact-two__bubble-1"></div>
                    <!-- /.contact-two__bubble-1 -->
                    <div class="contact-two__bubble-2"></div>
                    <!-- /.contact-two__bubble-2 -->
                </div>
                <!-- /.col-xl-5 -->
                <div class="col-xl-7">
                    <div class="contact-two__form-wrap">
                        <h3>Echangez avec nous</h3>
                        <form action="assets/inc/sendemail.php" class="contact-one__form">
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" placeholder="Votre Nom*">
                                </div>
                                <!-- /.col-md-6 -->
                                <div class="col-md-6">
                                    <input type="text" placeholder="Votre Email*">
                                </div>
                                <!-- /.col-md-6 -->
                                <div class="col-md-6">
                                    <input type="text" placeholder="Numéro de Téléphone">
                                </div>
                                <!-- /.col-md-6 -->
                                <div class="col-md-6">
                                    <input type="text" placeholder="Objet">
                                </div>
                                <!-- /.col-md-6 -->
                                <div class="col-md-12">
                                    <textarea name="message" placeholder="Rédigez votre méssages"></textarea>
                                    <!-- /# -->
                                </div>
                                <!-- /.col-md-12 -->
                                <div class="col-md-12 text-left">
                                    <button type="submit" class="thm-btn contact-one__btn">Envoyer</button>
                                </div>
                                <!-- /.col-md-12 -->
                            </div>
                            <!-- /.row -->
                        </form>
                        <!-- /.contact-one__form -->
                    </div>
                    <!-- /.contact-two__form-wrap -->
                </div>
                <!-- /.col-xl-7 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container text-center -->
    </section>
    <!-- /.page-header -->
<!-- /.page-wrapper -->
<a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
<div class="side-menu__block">
    <a href="#" class="side-menu__toggler side-menu__close-btn"><i class="fa fa-times"></i>
        <!-- /.fa fa-close --></a>
    <div class="side-menu__block-overlay custom-cursor__overlay">
        <div class="cursor"></div>
        <div class="cursor-follower"></div>
    </div>
    <!-- /.side-menu__block-overlay -->
    <div class="side-menu__block-inner ">

        <a href="index.html" class="side-menu__logo"><img src="assets/images/ilèra_logo.png" alt=""></a>
        <nav class="mobile-nav__container">
            <!-- content is loading via js -->
        </nav>
        <p class="side-menu__block__copy">(c) 2023 <a href="#">ILERA</a> - Tous droit réserver.</p>
        <div class="side-menu__social">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-google-plus"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-pinterest-p"></i></a>
        </div>
    </div>
    <!-- /.side-menu__block-inner -->
</div>
<!-- /.side-menu__block -->
<div class="search-popup">
    <div class="search-popup__overlay custom-cursor__overlay">
        <div class="cursor"></div>
        <div class="cursor-follower"></div>
    </div>
    <!-- /.search-popup__overlay -->
    <div class="search-popup__inner">
        <form action="#" class="search-popup__form">
            <input type="text" name="search" placeholder="Vous souhaitez....">
            <button type="submit"><i class="fa fa-search"></i></button>
        </form>
    </div>
    <!-- /.search-popup__inner -->
</div>
    `
    return (
        <div className='homeViewContent' dangerouslySetInnerHTML={{__html: contenuHTML}}>
            
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactIlera);