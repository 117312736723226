import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

import '../styles/dashboard.appointment.style.scss';
import loader from '../assets/gif/loader.gif';

import PATH_ from '../GLOBAL-VAR/globals-urls';
const Path = new PATH_();


function DashboardDoctorMeeting( props ) {
    const [isLoading, setIsLoading] = useState(false);
    
    function getUrlParams(url) {
        let urlStr = url.split('?')[1];
        const urlSearchParams = new URLSearchParams(urlStr);
        const result = Object.fromEntries(urlSearchParams.entries());
        return result;
    }
    
    const roomID = getUrlParams(window.location.href)['roomID'] || (Math.floor(Math.random() * 10000) + "");
    const userID = Math.floor(Math.random() * 10000) + "";
    const userName = "userName" + userID;
    const appID = 409831864;
    const serverSecret = "f3974d3cb1936f75831ad6958db744b4";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);

    useEffect(() => {
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        zp.joinRoom({
            container: document.querySelector("#_ROOT_"),
            sharedLinks: [{
                name: 'Personal link',
                url: Path.front_root + "meeting?roomID=" + roomID,
            }],
            scenario: {
                mode: ZegoUIKitPrebuilt.VideoConference,
            },

            turnOnMicrophoneWhenJoining: true,
            turnOnCameraWhenJoining: true,
            showMyCameraToggleButton: true,
            showMyMicrophoneToggleButton: true,
            showAudioVideoSettingsButton: true,
            showScreenSharingButton: true,
            showTextChat: true,
            showUserList: true,
            maxUsers: 50,
            layout: "Auto",
            showLayoutButton: true,
        });
    },[]);
        
    return (
        <>
        <div className='appointment'>
            {/* <iframe src={meetView}>
                <div className='' dangerouslySetInnerHTML={{__html: meetView}}></div>
            </iframe> */}
            <div id="_ROOT_">

            </div>
        </div>
        {
            isLoading ?
                <div>
                    <div className='globalALert_back'></div>
                    <div className='globalALert'>
                        <div className='globalALert_content'>
                            <div className='globalALert_content_loader'>
                                <img src={loader} alt='freeJobs'/>
                            </div>
                        </div>
                    </div>
                </div>
            :
                null
        }
        </>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDoctorMeeting);