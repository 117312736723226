import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import '../styles/dashboard.style.scss';

import DashboardPartnerProgram from './dashboard_partner.program.screen'

import userIMG from '../assets/images/people/Example18.jpg';

function _generateMenu(listElementsOfMenu, parent=null) {
    const currentPath = window.location.pathname;
    return (
        <div>
            {
                listElementsOfMenu.map((menu, index) => (
                    <div key={menu.name + "_" + index}>
                        <a
                            className={"dashboard-menu-content-item " + (currentPath.split(menu.link).length>1&&menu.name!=="Agenda"?" activeDashboard" : "inactiveDashboard")}
                            key={index + "_" + menu.name}
                            href={menu.link ? menu.link : "/#"}
                            onClick={() => {
                                if( !menu.subMenus ) {
                                    if(menu.action) {
                                        menu.action( index );
                                    }
                                }
                            }}
                            style={{
                                borderBottom: "1px solid #ffffff11"
                            }}
                        >
                            <div className='dashboard-menu-content-item_'>
                                <span className='dashboard-menu-content-item-icon'
                                    style={{
                                        color: "#ffffff",
                                    }}
                                >
                                    { menu.icon }
                                </span>
                                <span className='dashboard-menu-content-item-text'>
                                    {menu.name}
                                </span>
                            </div>

                            {
                                menu.subMenus ?
                                    <div>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right menuChevron" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            // style={{
                                            //     transform: this.state.currentSubMenuOpen.includes(menu.name) ? "rotate(90deg)" : "rotate(0deg)",
                                            // }}
                                        >
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </div>
                                :
                                    null
                            }
                        </a>
                        {
                            menu.subMenus ?
                                <div className='subMenu_container'
                                    // style={{
                                    //     opacity: this.state.currentSubMenuOpen.includes(menu.name) ? 1 : 0,
                                    //     maxHeight: this.state.currentSubMenuOpen.includes(menu.name) ? "50em" : "0em",
                                    // }}
                                >
                                    <div className='SubMenu'>
                                        {
                                            _generateMenu(menu.subMenus, menu.name)
                                        }
                                    </div>
                                </div>
                            :
                                null
                        }
                    </div>
                ))
            }
        </div>
    )
}


function DashboardPartner( props ) {
    const [user, setUser] = useState(null);
    const [viewMenu, setViewMenu] = useState(false);
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        setUser(currentUser);
    },[]);
    const menuList = [
        {
            icon:  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24">
                        <g data-name="agenda book" id="agenda_book">
                            <path d="M22,5.5A1.5,1.5,0,0,0,20.5,4H19V3.5A1.5,1.5,0,0,0,17.5,2H3.5A1.5,1.5,0,0,0,2,3.5v17A1.5,1.5,0,0,0,3.5,22h14A1.5,1.5,0,0,0,19,20.5V20h1.5A1.5,1.5,0,0,0,22,18.5v-3a1.49,1.49,0,0,0-.39-1,1.49,1.49,0,0,0,.39-1v-3a1.49,1.49,0,0,0-.39-1,1.49,1.49,0,0,0,.39-1ZM20.5,5a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5H19V5Zm.5,5.5v3a.5.5,0,0,1-.5.5H19V10h1.5A.5.5,0,0,1,21,10.5ZM15,3V7.29L13.85,6.15a.48.48,0,0,0-.7,0L12,7.29V3ZM18,20.5a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5V3.5A.5.5,0,0,1,3.5,3H11V8.5a.5.5,0,0,0,.31.46.48.48,0,0,0,.54-.11L13.5,7.21l1.65,1.64A.47.47,0,0,0,15.5,9a.43.43,0,0,0,.19,0A.5.5,0,0,0,16,8.5V3h1.5a.5.5,0,0,1,.5.5v17Zm3-2a.5.5,0,0,1-.5.5H19V15h1.5a.5.5,0,0,1,.5.5Z"/>
                            <path d="M15.5,11H5.5a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,0,15.5,11ZM15,15H6V12h9Z"/>
                            <path d="M15.5,18H5.5a.5.5,0,0,0,0,1h10a.5.5,0,0,0,0-1Z"/>
                        </g>
                    </svg>,
            name: "Agenda",
            link: "/dashboard/patient"
        },
        {
            icon:  <svg width="1em" height="1em" viewBox="0 0 16 16">
                        <path d="m 6.5 0 c -3.578125 0 -6.5 2.921875 -6.5 6.5 s 2.921875 6.5 6.5 6.5 c 0.167969 0 0.335938 -0.007812 0.5 -0.019531 v -2.007813 c -0.164062 0.019532 -0.332031 0.027344 -0.5 0.027344 c -2.496094 0 -4.5 -2.003906 -4.5 -4.5 s 2.003906 -4.5 4.5 -4.5 s 4.5 2.003906 4.5 4.5 c 0 0.167969 -0.007812 0.335938 -0.027344 0.5 h 2.007813 c 0.011719 -0.164062 0.019531 -0.332031 0.019531 -0.5 c 0 -3.578125 -2.921875 -6.5 -6.5 -6.5 z m 0 3 c -0.277344 0 -0.5 0.222656 -0.5 0.5 v 2.5 h -1.5 c -0.277344 0 -0.5 0.222656 -0.5 0.5 s 0.222656 0.5 0.5 0.5 h 2 c 0.277344 0 0.5 -0.222656 0.5 -0.5 v -3 c 0 -0.277344 -0.222656 -0.5 -0.5 -0.5 z m 4.5 5 v 3 h -3 v 2 h 3 v 3 h 2 v -3 h 3 v -2 h -3 v -3 z m 0 0" fill="currentColor"/>
                    </svg>,
            name: "Programmes",
            link: "/dashboard/partner/program"
        },{
            icon:   <svg width="1em" height="1em" viewBox="0 -0.5 25 25" fill="none">
                        <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="currentColor"/>
                    </svg>,
            name: "Déconnexion",
            link: "#",
            action: (index)=>{
                localStorage.removeItem("ilera");
                window.location.reload();
            }
        },
    ]

    return (
        <div>
            <main className='dashboard'>
                    <aside className={'dashboard-menu ' + (viewMenu? "menuACtive" : "menuInactive")} id="aside-menu">
                        <div className='dashboard-menu-content bg-color-blue no-view-scroll'>
                            <div className='dashboard-menu-content-head'>
                                <div className='dashboard-menu-content-head-img'>
                                    <img src={userIMG} alt='ilera'/>
                                </div>
                                <div className='dashboard-menu-content-head-text'>
                                    {/* <span>
                                        Bienvenu
                                    </span> */}
                                    <h2>
                                        {user!=null ? (user.name ? user.name : user.username) : ""}
                                    </h2>
                                </div>
                                <div className='dashboard-menu-content-head-close'>
                                    <button
                                        onClick={(e)=>{
                                            setViewMenu(false);
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Menu / Close_SM">
                                                <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className='dashboard-menu-content-body'>
                                {
                                    _generateMenu(menuList)
                                }
                            </div>
                        </div>
                    </aside>
                    <div className="dashboard-contain">
                        <div className='dashboard-contain-head'>
                            <div className='dashboard-contain-head-user'>
                                <div className='dashboard-contain-head-menu'>
                                    <button
                                        onClick={(e) => {
                                            setViewMenu(true);
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                            <path d="M4 6H20M4 12H14M4 18H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className='dashboard-contain-head-user-profile'>
                                    <a href='/dashboard/partner/profile'>
                                        <svg width="0.8em" height="0.8em" viewBox="0 0 20 20" version="1.1">
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Dribbble-Light-Preview" transform="translate(-180.000000, -2159.000000)" fill="currentColor">
                                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                                        <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span>
                                            Profil
                                        </span>
                                    </a>

                                    <a href='/'>
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                            <path d="M15 16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10L8 6.5M12 3L20 10L20 20H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span>
                                            Accueil
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Routes>
                            <Route path='*' element={<DashboardPartnerProgram/>}/>
                        </Routes>
                    </div>
                </main>
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPartner);