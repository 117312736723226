import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import 'react-chat-elements/dist/main.css';
import { ChatList } from 'react-chat-elements';
import { Input } from 'react-chat-elements';
import { MessageList } from 'react-chat-elements';
import { Button } from 'react-chat-elements';

// import AuthRequest from '../helpers/auth.requests.helper';
import ChatRequest from '../helpers/chat.requests.helper';
import getPreviewFiles from '../helpers/getPreviewFiles.helper';


import '../styles/dashboard.messages.style.scss';

import img1 from '../assets/images/giphy.gif';
import loader from '../assets/gif/loader.gif';

const inputReferance = React.createRef();
const messageListReferance = React.createRef();

const chat_ = new ChatRequest();

const currentUser = JSON.parse(localStorage.getItem("ilera"));
// const Auth = new AuthRequest();

function DashboardDoctorMessages( props ) {
    const [isLoading, setIsLoading] = useState(false);
    const [previewType, setPreviewType] = useState(null);
    const [preview, setPreview] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [currentConversation, setCurrentConversation] = useState([]);
    const [currentMessageContent, setCurrentMessageContent] = useState([]);


    function getMessageList() {
        setIsLoading(true);
        chat_.GetConversation(currentUser.ID).then((response) => {
            console.log(response.data);
            let data = response.data.conversations;
            for(let i=0; i<data.length; i++) {
                data[i].participants = data[i].participants.filter(objet => objet.id !== currentUser.ID);
                data[i]['avatar'] = img1;
                data[i]['title'] = data[i].participants.length>0 ? data[i].participants[0].username : "" ;
                data[i]['date'] = data[i].created_at;
            }
            console.log(data);
            setMessageList(data);
            // [{
            //     avatar: img1,
            //     alt: 'Reactjs',
            //     title: 'Facebook',
            //     subtitle: 'What are you doing?',
            //     date: new Date(),
            //     unread: 1,
            // }]
            setIsLoading(false);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function sendTextMessage(message) {
        setIsLoading(true);
        let data = {
            conversation_id: currentConversation.id,
            sender_id: currentUser.ID,
            content: message
        };
        console.log(data);
        chat_.SendTextMessage(data).then((res)=>{
            setIsLoading(false);
            if(res.data.success) {
                let newMessage = {
                    position: 'right',
                    text: message,
                    type: 'text',
                    sender_id: currentUser.ID,
                    date: new Date(),
                    id: res.data.message_id
                };
                setCurrentMessageContent(prevTableauObject => [...prevTableauObject, newMessage]);
                inputReferance.current.value = "";
            }
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function sendFileMessage(message) {
        setIsLoading(true);
        let data = {
            conversation_id: currentConversation.id,
            sender_id: currentUser.ID,
            content: message,
            file: document.getElementById("inputImageFiles").files[0],
        };
        chat_.SendTextMessage(JSON.stringify(data)).then((res)=>{
            setIsLoading(false);
            setPreview(null);
            setPreviewType(null);
            if(res.data.success) {
                let newMessage = {
                    position: 'right',
                    text: message,
                    type: 'text',
                    sender_id: currentUser.ID,
                    date: new Date(),
                    id: res.data.message_id
                };
                setCurrentMessageContent(prevTableauObject => [...prevTableauObject, newMessage]);
                inputReferance.current.value = "";
            }
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function getConversationMessages(conversation) {
        console.log(conversation);
        setCurrentConversation(conversation);
        setIsLoading(true);
        chat_.GetConversationMessages(conversation.id).then((res)=>{
            setIsLoading(false);
            setCurrentConversation(conversation);
            console.log(res.data);
            let messages = res.data.messages;
            messages = messages.map(objet => ({
                position: objet.sender_id === currentUser.ID ? 'right' : 'left',
                text: objet.content,
                type: 'text',
                date: new Date(objet.timestamp),
                title: objet.sender_id === currentUser.ID ? 'vous' : '',
            }));
            setCurrentMessageContent(messages);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getMessageList();
    },[]);

    return (
        <div className='messages'>
            <div className='messages_content'>
                <div className='messages_content_chatList'>
                    <ChatList
                        className='chat-list'
                        dataSource={
                            messageList
                        }
                        onClick={(e)=>{
                            console.log(e);
                            getConversationMessages(e);
                        }}
                    />
                </div>
                <div className='messages_content_right'>
                    <div className='messages_content_right_messageList'>
                        <MessageList
                            referance={messageListReferance}
                            className='message-list'
                            lockable={true}
                            toBottomHeight={'100%'}
                            messageBoxStyles={{
                                marginBottom: "20px",
                                boxShadow: "1px 1px 5px 1px #00000033",
                                objectFit: "contain",
                                backgroundSize: "contain",
                            }}
                            dataSource={ currentMessageContent }
                        />
                    </div>
                    <div className='messages_content_right_input'>
                        <div className='messages_content_right_input_previewClose'
                             style={{
                                display: preview === null ? "none" : "flex",
                            }}
                        >
                            <button
                                onClick={(e)=>{
                                    setPreview(null);
                                    document.getElementById("inputImageFiles").value = "";
                                }}
                            >
                                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Menu / Close_SM">
                                        <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className='messages_content_right_input_preview'
                            style={{
                                opacity: preview === null ? 1 : 1,
                                height: preview === null ? "0px" : "200px",
                                marginBottom: preview === null ? "-12px" : "0px"
                            }}
                        >
                            {
                                preview!==null && preview.map((content)=>(
                                    previewType === 'img' ?
                                        <img src={content.src} alt='ilera'/>
                                    :
                                        null
                                ))
                            }
                        </div>
                        <div className='messages_content_right_input_file'>
                            <div className='messages_content_right_input_file_elt'>
                                <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                    <mask id="mask0_1358_2896" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                                    <path d="M3 11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11Z" fill="currentColor"/>
                                    </mask>
                                    <g mask="url(#mask0_1358_2896)">
                                    <path d="M5.40989 12.5901L5.25713 12.7429C4.27646 13.7235 3.78613 14.2139 3.49264 14.8158C3.39066 15.025 3.30712 15.2427 3.24299 15.4664C3.05843 16.1102 3.09488 16.8027 3.16777 18.1877L3.5 24.5H21V19.7573C21 18.3059 21 17.5802 20.7614 16.9207C20.6962 16.7404 20.6181 16.565 20.5277 16.3959C20.1971 15.7774 19.6577 15.2919 18.5789 14.321L18.3643 14.1279C17.4682 13.3214 17.0202 12.9182 16.5078 12.8039C16.1864 12.7322 15.8523 12.741 15.5352 12.8295C15.0295 12.9705 14.6033 13.3967 13.7508 14.2492C13.1184 14.8816 12.8023 15.1977 12.4625 15.2406C12.2519 15.2672 12.0383 15.226 11.8526 15.1231C11.5531 14.9572 11.3742 14.5399 11.0166 13.7053C10.2559 11.9304 9.87554 11.0429 9.22167 10.7151C8.89249 10.5501 8.52413 10.4792 8.1572 10.5101C7.42836 10.5716 6.75554 11.2445 5.40989 12.5901L5.40989 12.5901Z" fill="currentColor" fill-opacity="0.24" stroke="currentColor"/>
                                    </g>
                                    <path d="M3 11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11Z" stroke="currentColor" stroke-width="1.2"/>
                                    <circle cx="16.5" cy="7.5" r="1.5" fill="currentColor"/>
                                </svg>
                                <input id="inputImageFiles" type='file' accept="image/*"
                                    onChange={ async (e) => {
                                        setPreviewType("img");
                                        const files = Array.from( e.target.files );
                                        
                                        getPreviewFiles( files ).then( images => {
                                            let tab = [];
                                            images.map((image) => {
                                                tab = tab.concat({
                                                    src: image,
                                                });
                                                return tab;
                                            });
                                            setPreview(tab);
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <Input
                            referance={inputReferance}
                            placeholder='Votre méssage...'
                            multiline={true}
                            value={""}
                            inputStyle={{
                                width: "100%",
                                border: "1px solid #00000022",
                                padding: "10px",
                                color: "#000000aa"
                            }}
                            rightButtons={
                                <Button color='white' backgroundColor='#3871a5' text='Envoyer'
                                    onClick={(e)=>{
                                        if(previewType===null) {
                                            sendTextMessage(inputReferance.current.value);
                                        } else {
                                            sendFileMessage(inputReferance.current.value);
                                        }
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </div>

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDoctorMessages);