import React, { useState }from 'react';
import { connect } from 'react-redux';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import '../styles/login.screen.style.scss';

/**Importation des images */
import ilera from '../assets/images/ilèra_logo.png'
import loader from '../assets/gif/loader.gif';

/**Importation des screens pour les differentes etapes */

import Verifier from '../helpers/verifier.helper';
import UserRequest from '../helpers/user.requests.helper';

const VERF = new Verifier();

const USR = new UserRequest();

function RegistrationView( props ) {

    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [born, setBorn] = useState("");

    const [password_verification_results, setpassword_verification_results] = useState(
        {
            number: false,
            string: false,
            special_character: false,
            minimum_character: false,
            like_personal_information: true,
            isOk: false,
            isConfirmed: false,
        }
    );

    const [step, setStep] = useState(0);

    function addUser() {
        setIsLoading(true);
        let user = {
            // nom : document.forms["registrationForm"]["lastName"].value,
            // prenom : document.forms["registrationForm"]["firstName"].value,
            email : email,
            username : email,
            password : document.forms["registrationForm"]["password"].value,
            password_confirm : document.forms["registrationForm"]["confirmPassword"].value,
            date_naissance: born,
            numero_telephone: phoneNumber,
        };
        // console.log(user);
        USR.add(user).then((response) => {
            setIsLoading(false);
            let res = response.data;
            console.log(response);
            // localStorage.setItem("ilera", JSON.stringify(user));
            if(res.success === true) {
                alert(res.message);
                window.location.href = "/login";
            } else {
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
        }).catch((e) => {
            console.log(e.response.data);
            if(e.response.data.errors) {
                if(e.response.data.errors.user) {
                    if(e.response.data.errors.user.username) {
                        if(e.response.data.errors.user.username[0] === "A user with that username already exists.") {
                            alert("Un utilisateur avec cet email existe déjà.");
                        }
                    }
                }
            }
            setIsLoading(false);
        });
    }

    return (
        <div className='login'>
            <div className='login_content'>
                {
                    step > 0 ?
                        <div className="login_content_head_back">
                            <button
                                onClick={(e)=>{
                                    setStep((prev)=>{
                                        return (prev-1)
                                    });
                                }}
                            >
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                </svg>
                            </button>
                        </div>
                    : null
                }
                <div className='login_content_head'>
                    
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Créer un compte
                        </div>
                        <div className='login_content_head_title_bottom'>
                            <span>Veuillez saisir vos informations pour rejoindre ilera</span> 
                            {/* <img src={ilera} alt='ilera'/> */}
                        </div>
                    </div>
                </div>

                {
                    step === 0 ? 
                        <form action='#' method='POST' className='login_content_form' name='registrationForm'
                            onSubmit={(e) => {
                                e.preventDefault();
                                setStep(1);
                            }}
                        >
            
                            <div className='login_content_form_input'>
                                <div className='login_content_form_input_title'>
                                    <span>Email</span>
                                </div>
                                <div className='login_content_form_input_balise'>
                                    <input value={email} name='email' required placeholder='email@gmail.com' type='email'
                                        onChange={(e)=>{
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='login_content_form_input_subText'>
                                    <span>Votre email</span>
                                </div>
                            </div>
            
                            <div className="steps_s">
                                <div className='step_s valid'></div>
                                <div className='step_s'></div>
                                <div className='step_s'></div>
                                <div className='step_s'></div>
                            </div>
                            <br/>
            
                            <div className='login_content_form_button'>
                                <button>
                                    CONTINUER
                                </button>
                                <a href='/login'>
                                    Se connecter
                                </a>
                            </div>
            
                            <div className='login_content_form_button returnHome'>
                                <a href='/'>
                                    Retourner à l'accueil
                                </a>
                            </div>
                        </form>
                    :
                        (
                            step === 1 ?
                                <form action='#' method='POST' className='login_content_form' name='registrationForm'
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setStep(2);
                                    }}
                                >
                                    <div className='login_content_form_input'>
                                        <div className='login_content_form_input_title'>
                                            <span>Numéro de téléphone</span>
                                        </div>

                                        <div className='login_content_form_input_balise'>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                            />
                                        </div>
                                        
                                        <div className='login_content_form_input_subText'>
                                            <span>Votre numéro de téléphone</span>
                                        </div>
                                    </div>

                                    <div className="steps_s">
                                        <div className='step_s valid'></div>
                                        <div className='step_s valid'></div>
                                        <div className='step_s'></div>
                                        <div className='step_s'></div>
                                    </div>
                                    <br/>
                    
                                    <div className='login_content_form_button'>
                                        <button>
                                            CONTINUER
                                        </button>
                                        <a href='/login'>
                                            Se connecter
                                        </a>
                                    </div>
                    
                                    <div className='login_content_form_button returnHome'>
                                        <a href='/'>
                                            Retourner à l'accueil
                                        </a>
                                    </div>
                                </form>
                            :
                                (
                                    step === 2 ?
                                        <form action='#' method='POST' className='login_content_form' name='registrationForm'
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                setStep(3);
                                            }}
                                        >
                                            <div className='login_content_form_input'>
                                                <div className='login_content_form_input_title'>
                                                    <span>Date de naissance</span>
                                                </div>
                                                <div className='login_content_form_input_balise'>
                                                    <input value={born} name='born' required placeholder='dd/mm/yy' type='date'
                                                        onChange={(e)=>{
                                                            setBorn(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="steps_s">
                                                <div className='step_s valid'></div>
                                                <div className='step_s valid'></div>
                                                <div className='step_s valid'></div>
                                                <div className='step_s'></div>
                                            </div>
                                            <br/>
                            
                                            <div className='login_content_form_button'>
                                                <button>
                                                    CONTINUER
                                                </button>
                                                <a href='/login'>
                                                    Se connecter
                                                </a>
                                            </div>
                            
                                            <div className='login_content_form_button returnHome'>
                                                <a href='/'>
                                                    Retourner à l'accueil
                                                </a>
                                            </div>
                                        </form>
                                    :
                                        (
                                            step === 3 ?
                                                <form action='#' method='POST' className='login_content_form' name='registrationForm'
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        addUser();
                                                    }}
                                                >
                                                    <div className='login_content_form_input'>
                                                        <div className='login_content_form_input_title'>
                                                            <span>Mot de passe</span>
                                                        </div>
                                                        <div className='login_content_form_input_balise'>
                                                            <input name='password' required placeholder='***********' type='password'
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    let password_verification_results_ = {
                                                                        number: false,
                                                                        string: false,
                                                                        special_character: false,
                                                                        minimum_character: false,
                                                                        like_personal_information: true,
                                                                        isOk: false,
                                                                        isConfirmed: false,
                                                                    };
                                                                    let result = VERF._password(value, 8);

                                                                    password_verification_results_.minimum_character = result.minimum_character;
                                                                    password_verification_results_.special_character = result.special_character;
                                                                    password_verification_results_.string = result.string;
                                                                    password_verification_results_.number = result.number;

                                                                    
                                                                    if( 
                                                                        ( born.length > 0 && value.toLowerCase().split(born.toLowerCase()).length > 1 )||
                                                                        ( email.length > 0 && value.toLowerCase().split(email.toLowerCase()).length > 1 )||
                                                                        ( phoneNumber.length > 0 && value.toLowerCase().split(phoneNumber.toLowerCase()).length > 1 )
                                                                        // ( document.forms["registrationForm"]["mail"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["mail"].value.toLowerCase()).length > 1 )
                                                                    ) {
                                                                        password_verification_results_.like_personal_information = true;
                                                                    }else {
                                                                        password_verification_results_.like_personal_information = false;
                                                                    }

                                                                    if( document.forms["registrationForm"]["confirmPassword"].value.length > 0 &&  document.forms["registrationForm"]["confirmPassword"].value === value ) {
                                                                        password_verification_results_.isConfirmed = true;
                                                                    }else {
                                                                        password_verification_results_.isConfirmed = false;
                                                                    }

                                                                    setpassword_verification_results(password_verification_results_);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='login_content_form_input_verf'>
                                                            <span
                                                                style={{
                                                                    color: password_verification_results.like_personal_information ? "#d11919" : "#5dc269",
                                                                }}
                                                            >- Votre mot de passe ne peut pas trop ressembler à vos autres informations personnelles.</span>
                                                            
                                                            <span
                                                                style={{
                                                                    color: !password_verification_results.minimum_character ? "#d11919" : "#5dc269",
                                                                }}
                                                            >- Votre mot de passe doit contenir au minimum 8 caractères.</span>
                                                            
                                                            <span
                                                                style={{
                                                                    color: !password_verification_results.number ? "#d11919" : "#5dc269",
                                                                }}
                                                            >- Votre mot de passe doit contenir au moins un chiffre.</span>
                                                            
                                                            <span
                                                                style={{
                                                                    color: !password_verification_results.string ? "#d11919" : "#5dc269",
                                                                }}
                                                            >- Votre mot de passe doit contenir au moins une lettre.</span>
                                                            
                                                            <span
                                                                style={{
                                                                    color: !password_verification_results.special_character ? "#d11919" : "#5dc269",
                                                                }}
                                                            >- Votre mot de passe doit contenir au moins un des caractères suivant : « @ », « # », « % », « & », « $ » et « _ ».</span>
                                                        </div>
                                                    </div>

                                                    <div className='login_content_form_input'>
                                                        <div className='login_content_form_input_title'>
                                                            <span>Confirmez votre mot de passe</span>
                                                        </div>
                                                        <div className='login_content_form_input_balise'>
                                                            <input name='confirmPassword' required placeholder='***********' type='password'
                                                                onChange={(e) => {
                                                                    let value = e.target.value;

                                                                    if( document.forms["registrationForm"]["password"].value.length > 0 &&  document.forms["registrationForm"]["password"].value === value ) {
                                                                        setpassword_verification_results({
                                                                            ...password_verification_results,
                                                                            isConfirmed: true,
                                                                        })
                                                                    }else {
                                                                        setpassword_verification_results({
                                                                            ...password_verification_results,
                                                                            isConfirmed: false,
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='login_content_form_input_verf'>
                                                            <span
                                                                style={{
                                                                    color: !password_verification_results.isConfirmed ? "#d11919" : "#5dc269",
                                                                }}
                                                            >confirmer</span>
                                                        </div>
                                                    </div>
                                                    <div className="steps_s">
                                                        <div className='step_s valid'></div>
                                                        <div className='step_s valid'></div>
                                                        <div className='step_s valid'></div>
                                                        <div className='step_s valid'></div>
                                                    </div>
                                                    <br/>
                                    
                                                    <div className='login_content_form_button'>
                                                        <button>
                                                            VALIDER
                                                        </button>
                                                        <a href='/login'>
                                                            Se connecter
                                                        </a>
                                                    </div>
                                    
                                                    <div className='login_content_form_button returnHome'>
                                                        <a href='/'>
                                                            Retourner à l'accueil
                                                        </a>
                                                    </div>
                                                </form>
                                            :
                                                null
                                        )
                                )
                        )
                }
            </div>

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationView);