// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';

import 'react-big-calendar/lib/css/react-big-calendar.css';

/**Importation des images*/
import logo from '../../assets/images/img1.jpg';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

const localizer = momentLocalizer(moment)

function CardEvodoun( props ) {
    return (
        <div className='CardEvodoun_container'>
            <div className='CardEvodoun_container_head'>
                <a href={props.content.photo}
                    // style={{
                    //     backgroundImage: "url("+ props.content.photo + ")"
                    // }}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onclickImageAction();
                    }}
                >
                    <img src={props.content.picture ? props.content.picture : logo} alt={"evodoun_" + props.content.nom}/>
                    <div className='CardEvodoun_container_head_fake'>
                        <div className='tarifs'>
                            <h3>Tarifs</h3>
                            <ul>
                                <li>
                                    - Consultaions vidéos: <strong>3000 XOF</strong>
                                </li>
                                <li>
                                    - Consultaions au cabinet: <strong>2500 XOF</strong>
                                </li>
                                <li>
                                    - Consultaions à domicile: <strong>4000 XOF</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='CardEvodoun_container_head_fake_2'>
                        <div className='CardEvodoun_container_head_fake_2_circle'></div>
                    </div> */}
                </a>
            </div>
            {/* <div className='CardEvodoun_container_icon'>
                <span data-content={props.icon.value} className={"fa " + props.icon.className}></span>
            </div> */}
            <div className='CardEvodoun_container_info'>
                {/* <div className='CardEvodoun_container_info_flag'>
                    <img src={props.content.country.flag} alt={"evodoun_"+props.content.country.name}/>
                </div> */}
                <div className='CardEvodoun_container_info_name'>
                    <a href={"##"}>
                        {props.content.nom} {props.content.prenom}
                    </a>
                </div>
                <div className='CardEvodoun_container_info_profession'>
                    <span>
                        {props.content.adresse}
                    </span>
                </div>
                <div>
                    <Calendar
                        localizer={localizer}
                        events={[]}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 300 }}
                    />
                </div>
            </div>
            <div className='CardEvodoun_container_foot'>
                <button
                    onClick={()=>{
                        if (props.click_) {
                            props.click_()
                        } else {
                            return true;
                        }
                    }}
                >
                    <span>PRENDRE UN RENDEZ-VOUS</span>
                    <span className='CardEvodoun_container_foot_fake'></span>
                </button>
            </div>
        </div>
    )
}

export default CardEvodoun;