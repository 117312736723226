import * as React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

/**Importation des vues */
import Home from "../screens/home.screen";
import AboutIlera from '../screens/aboutIlera.screen';
import FaqIlera from '../screens/faqIlera.screen copy';
import ContactIlera from '../screens/contactIlera.screen';
import TarifView from '../screens/tarifView.screen';
import PaymentsView from '../screens/paymentsView.screen';
import publicMeetingScreen from "../screens/public.meeting.screen";
import SearchDoctorView from "../screens/searchDoctor.screen.jsx";

import DashboardDoctor from "../screens/dashboard_doctor.screen";
import DashboardDoctorAppointment from "../screens/dashboard_doctor.appointment.screen";
import DashboardDoctorPrescription from '../screens/dashboard_doctor.prescription.screen';
import DashboardDoctorPatients from '../screens/dashboard_doctor.patients.screen';
import DashboardDoctorPayments from '../screens/dashboard_doctor.payments.screen';
import DashboardDoctorMessages from '../screens/dashboard_doctor.messages.screen';
import DashboardDoctorMeeting from '../screens/dashboard_doctor.meeting.screen';

import DashboardPatient from "../screens/dashboard_patient.screen";
import DashboardPatientAppointment from "../screens/dashboard_patient.appointment.screen";
import DashboardPatientPrescription from '../screens/dashboard_patient.prescription.screen';
import DashboardPatientPayments from '../screens/dashboard_patient.payments.screen';
import DashboardPatientMessages from '../screens/dashboard_patient.messages.screen';
import DashboardPatientRequest from '../screens/dashboard_patient.request.screen';
import DashboardPatientProfile from '../screens/dashboard_patient.profile.screen';

import DashboardAdmin from "../screens/dashboard_admin.screen";
import DashboardAdminAppointment from "../screens/dashboard_admin.appointment.screen";
import DashboardAdminPrescription from '../screens/dashboard_admin.prescription.screen';
import DashboardAdminPatients from '../screens/dashboard_admin.patients.screen';
import DashboardAdminPayments from '../screens/dashboard_admin.payments.screen';
import DashboardAdminDoctors from '../screens/dashboard_admin.doctors.screen';
import DashboardAdminPartners from '../screens/dashboard_admin.partners.screen';
// import DashboardAdminProfile from '../screens/dashboard_admin.profile.screen';
import DashboardAdminAGenda from '../screens/dashboard_admin.agenda.screen';
// import dashboardAdminUsers from "../screens/dashboard_admin.users.screen";

import DashboardPartner from '../screens/dashboard_partner.screen';
import DashboardPartnerProgram from '../screens/dashboard_partner.program.screen';

import LoginView from '../screens/login.screen';
import RegistrationView from '../screens/registration.screen';

import ErrorPage from "../screens/error.screen";
import PublicMeetingScreen from "../screens/public.meeting.screen";

const session = JSON.parse(localStorage.getItem("ilera"));
// console.log(session);
// localStorage.removeItem("ilera");


const router_1 = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [{
            path: "about-ilera",
            element: <AboutIlera/>
        },{
            path: "faq",
            element: <FaqIlera/>
        },{
            path: "contact-us",
            element: <ContactIlera/>
        },{
            path: "tarifs",
            element: <TarifView/>
        },{
            path: "payment",
            element: <PaymentsView/>
        },{
            path: "meeting",
            element: <PublicMeetingScreen/>
        },{
            path: "search/:type",
            element: <SearchDoctorView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/about-ilera",
        element: <AboutIlera/>,
        errorElement: <ErrorPage/>,
    }
    /**Les docteur */
    ,{
        path: "/dashboard/doctor/",
        element: <DashboardDoctor/>,
        children: [{
            path: "appointment",
            element: <DashboardDoctorAppointment/>
        },{
            path: "prescription",
            element: <DashboardDoctorPrescription/>
        },{
            path: "patients",
            element: <DashboardDoctorPatients/>
        },{
            path: "payments",
            element: <DashboardDoctorPayments/>
        },{
            path: "messages",
            element: <DashboardDoctorMessages/>
        },{
            path: "profile",
            element: <DashboardPatientProfile/>
        }],
        errorElement: <ErrorPage/>,
    }
    /**Les patients */
    ,{
        path: "/dashboard/patient/",
        element: <DashboardPatient/>,
        children: [{
            path: "appointment",
            element: <DashboardPatientAppointment/>
        },{
            path: "prescription",
            element: <DashboardPatientPrescription/>
        },{
            path: "payments",
            element: <DashboardPatientPayments/>
        },{
            path: "messages",
            element: <DashboardPatientMessages/>
        },{
            path: "request",
            element: <DashboardPatientRequest/>
        },{
            path: "profile",
            element: <DashboardPatientProfile/>
        }],
        errorElement: <ErrorPage/>,
    }
    /**Les administrateurs */
    ,{
        path: "/dashboard/admin/",
        element: <DashboardAdmin/>,
        children: [{
            path: "appointment",
            element: <DashboardAdminAppointment/>
        },{
            path: "prescription",
            element: <DashboardAdminPrescription/>
        },{
            path: "patients",
            element: <DashboardAdminPatients/>
        },{
            path: "doctors",
            element: <DashboardAdminDoctors/>
        },{
            path: "payments",
            element: <DashboardAdminPayments/>
        },{
            path: "partners",
            element: <DashboardAdminPartners/>
        },{
            path: "profile",
            element: <DashboardPatientProfile/>
        },{
            path: "agenda",
            element: <DashboardAdminAGenda/>
        },{
            path: "users",
            element: <dashboardAdminUsers/>
        }],
        errorElement: <ErrorPage/>,
    }
    /**Les partenaires */
    ,{
        path: "/dashboard/partner/",
        element: <DashboardPartner/>,
        children: [{
            path: "program",
            element: <DashboardPartnerProgram/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/registration",
        element: <RegistrationView/>,
        errorElement: <ErrorPage/>,
    }
]);


/**Les docteur */
const router_1_1 = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [{
            path: "about-ilera",
            element: <AboutIlera/>
        },{
            path: "faq",
            element: <FaqIlera/>
        },{
            path: "contact-us",
            element: <ContactIlera/>
        },{
            path: "tarifs",
            element: <TarifView/>
        },{
            path: "payment",
            element: <PaymentsView/>
        },{
            path: "meeting",
            element: <PublicMeetingScreen/>
        },{
            path: "search/:type",
            element: <SearchDoctorView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/doctor/",
        element: <DashboardDoctor/>,
        children: [{
            path: "appointment",
            element: <DashboardDoctorAppointment/>
        },{
            path: "prescription",
            element: <DashboardDoctorPrescription/>
        },{
            path: "patients",
            element: <DashboardDoctorPatients/>
        },{
            path: "payments",
            element: <DashboardDoctorPayments/>
        },{
            path: "messages",
            element: <DashboardDoctorMessages/>
        },{
            path: "profile",
            element: <DashboardPatientProfile/>
        },{
            path: "meeting",
            element: <DashboardDoctorMeeting/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/registration",
        element: <RegistrationView/>,
        errorElement: <ErrorPage/>,
    }
]);


/**Les patients */
const router_1_2 = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [{
            path: "about-ilera",
            element: <AboutIlera/>
        },{
            path: "faq",
            element: <FaqIlera/>
        },{
            path: "contact-us",
            element: <ContactIlera/>
        },{
            path: "tarifs",
            element: <TarifView/>
        },{
            path: "payment",
            element: <PaymentsView/>
        },{
            path: "meeting",
            element: <PublicMeetingScreen/>
        },{
            path: "search/:type",
            element: <SearchDoctorView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/patient/",
        element: <DashboardPatient/>,
        children: [{
            path: "appointment",
            element: <DashboardPatientAppointment/>
        },{
            path: "prescription",
            element: <DashboardPatientPrescription/>
        },{
            path: "payments",
            element: <DashboardPatientPayments/>
        },{
            path: "messages",
            element: <DashboardPatientMessages/>
        },{
            path: "request",
            element: <DashboardPatientRequest/>
        },{
            path: "profile",
            element: <DashboardPatientProfile/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/registration",
        element: <RegistrationView/>,
        errorElement: <ErrorPage/>,
    }
]);


/**Les administrateurs */
const router_1_3 = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [{
            path: "about-ilera",
            element: <AboutIlera/>
        },{
            path: "faq",
            element: <FaqIlera/>
        },{
            path: "contact-us",
            element: <ContactIlera/>
        },{
            path: "tarifs",
            element: <TarifView/>
        },{
            path: "payment",
            element: <PaymentsView/>
        },{
            path: "meeting",
            element: <PublicMeetingScreen/>
        },{
            path: "search/:type",
            element: <SearchDoctorView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/admin/",
        element: <DashboardAdmin/>,
        children: [{
            path: "appointment",
            element: <DashboardAdminAppointment/>
        },{
            path: "prescription",
            element: <DashboardAdminPrescription/>
        },{
            path: "patients",
            element: <DashboardAdminPatients/>
        },{
            path: "doctors",
            element: <DashboardAdminDoctors/>
        },{
            path: "payments",
            element: <DashboardAdminPayments/>
        },{
            path: "profile",
            element: <DashboardPatientProfile/>
        },{
            path: "partners",
            element: <DashboardAdminPartners/>
        },{
            path: "users",
            element: <dashboardAdminUsers/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/registration",
        element: <RegistrationView/>,
        errorElement: <ErrorPage/>,
    }
]);

/**Les administrateurs */
const router_1_4 = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [{
            path: "about-ilera",
            element: <AboutIlera/>
        },{
            path: "faq",
            element: <FaqIlera/>
        },{
            path: "contact-us",
            element: <ContactIlera/>
        },{
            path: "tarifs",
            element: <TarifView/>
        },{
            path: "payment",
            element: <PaymentsView/>
        },{
            path: "meeting",
            element: <PublicMeetingScreen/>
        },{
            path: "search/:type",
            element: <SearchDoctorView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/partner/",
        element: <DashboardAdminPartners/>,
        children: [{
            path: "program",
            element: <DashboardPartnerProgram/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/registration",
        element: <RegistrationView/>,
        errorElement: <ErrorPage/>,
    }
]);


// const router_1_4 = createBrowserRouter([
//     {
//         path: "/",
//         element: <Home/>,
//         children: [{
//             path: "about-ilera",
//             element: <AboutIlera/>
//         },{
//             path: "faq",
//             element: <FaqIlera/>
//         },{
//             path: "contact-us",
//             element: <ContactIlera/>
//         },{
//             path: "tarifs",
//             element: <TarifView/>
//         }],
//         errorElement: <ErrorPage/>,
//     },{
//         path: "/dashboard/admin/",
//         element: <DashboardAdmin/>,
//         children: [{
//             path: "appointment",
//             element: <DashboardAdminAppointment/>
//         },{
//             path: "prescription",
//             element: <DashboardAdminPrescription/>
//         },{
//             path: "patients",
//             element: <DashboardAdminPatients/>
//         },{
//             path: "doctors",
//             element: <DashboardAdminDoctors/>
//         },{
//             path: "payments",
//             element: <DashboardAdminPayments/>
//         },{
//             path: "profile",
//             element: <DashboardAdminProfile/>
//         },{
//             path: "partners",
//             element: <DashboardAdminPartners/>
//         }],
//         errorElement: <ErrorPage/>,
//     },{
//         path: "login",
//         element: <LoginView/>,
//         errorElement: <ErrorPage/>,
//     },{
//         path: "/registration",
//         element: <RegistrationView/>,
//         errorElement: <ErrorPage/>,
//     }
// ]);


const router_2 = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [{
            path: "about-ilera",
            element: <AboutIlera/>
        },{
            path: "faq",
            element: <FaqIlera/>
        },{
            path: "contact-us",
            element: <ContactIlera/>
        },{
            path: "tarifs",
            element: <TarifView/>
        },{
            path: "payment",
            element: <PaymentsView/>
        },{
            path: "meeting",
            element: <PublicMeetingScreen/>
        },{
            path: "search/:type",
            element: <SearchDoctorView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/doctor/",
        element: <LoginView/>,
        children: [{
            path: "appointment",
            element: <LoginView/>
        },{
            path: "prescription",
            element: <LoginView/>
        },{
            path: "patients",
            element: <LoginView/>
        },{
            path: "payments",
            element: <LoginView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/patient/",
        element: <LoginView/>,
        children: [{
            path: "appointment",
            element: <LoginView/>
        },{
            path: "prescription",
            element: <LoginView/>
        },{
            path: "payments",
            element: <LoginView/>
        },{
            path: "request",
            element: <LoginView/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/admin/",
        element: <LoginView/>,
        children: [{
            path: "appointment",
            element: <LoginView/>
        },{
            path: "prescription",
            element: <LoginView/>
        },{
            path: "patients",
            element: <LoginView/>
        },{
            path: "payments",
            element: <LoginView/>
        },{
            path: "doctors",
            element: <LoginView/>
        },{
            path: "profile",
            element: <LoginView/>
        },{
            path: "partners",
            element: <LoginView/>
        },{
            path: "users",
            element: <dashboardAdminUsers/>
        }],
        errorElement: <ErrorPage/>,
    },{
        path: "/login",
        element: <LoginView/>,
        errorElement: <ErrorPage/>,
    },{
        path: "/registration",
        element: <RegistrationView/>,
        errorElement: <ErrorPage/>,
    }
]);

const TEST = false;

function Route() {
    if(TEST) {
        return (
            <RouterProvider router={router_1}/>
        );
    } else {
        return (
            <RouterProvider router={session !== null ? 
                ( session.role==="administrateur" ? 
                    router_1_3 : ( (session.role === "Patient" || session.role === "patient") ? 
                        router_1_2 : ((session.role === "Médecin" || session.role === "medecin" || session.role === "Medecin") ? 
                            router_1_1 : (session.role === "Partenaire" ? 
                                router_1_4 : router_1_2
                            )
                        )
                    ) 
                ) : router_2}/>
        );
    }
}
  
export default Route;