import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import ImageSlider1 from '../components/imageSlider1/imageSlider1.component';
import Card from '../components/card_s/card.component';

import '../styles/homeView.screen.style.scss';

/**Importation des images */
import lines from '../assets/images/steps-line-1-1.png';
import ilera_logo_small from '../assets/images/ilera.png';
import teleconsult from '../assets/images/teleconsultation.png';
import discuss from '../assets/images/discuss.png';
import partage from '../assets/images/partage.png';
// import lendImg from '../assets/images/baneer_1.png';
import loader from '../assets/gif/loader.gif';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import UserRequest from '../helpers/user.requests.helper';
import PATH_ from '../GLOBAL-VAR/globals-urls';
import Xanimation from '../helpers/x-animations';


const USR = new UserRequest();
const PATH__ = new PATH_();

const animer = new Xanimation();
animer.init( 300 );

function HomeView( props ) {
    const [viewTarif, setViewTarif] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const [specialite, setSpecialite] = useState([]);
    const [address, setAddress] = useState([
        {"name": "Cotonou"},
        {"name": "Porto-Novo"},
        {"name": "Parakou"},
        {"name": "Abomey-Calavi"},
        {"name": "Djougou"},
        {"name": "Bohicon"},
        {"name": "Kandi"},
        {"name": "Lokossa"},
        {"name": "Ouidah"},
        {"name": "Abomey"},
        {"name": "Natitingou"},
        {"name": "Save"},
        {"name": "Nikki"},
        {"name": "Bembèrèkè"},
        {"name": "Malanville"},
        {"name": "Pobe"},
        {"name": "Savalou"},
        {"name": "Sakete"},
        {"name": "Come"},
        {"name": "Dogbo-Tota"},
        {"name": "Bassila"},
        {"name": "Banikoara"},
        {"name": "Ketou"},
        {"name": "Kouande"},
        {"name": "Tchaourou"},
        {"name": "Allada"},
        {"name": "Aplahoue"},
        {"name": "Dassa-Zoume"},
        {"name": "Beterou"},
        {"name": "Tanguieta"},
        {"name": "Copargo"},
        {"name": "N`Dali"},
        {"name": "N`Dali"},
        {"name": "Adjohoun"},
        {"name": "Comè"},
        {"name": "Kandi"}
    ]);
    const [searchValue, setSearchValue] = useState([]);
    const [searchAddress, setSearchAddress] = useState([]);
    
    const specialities = [
        "Médecin Généraliste",
        "Cardiologue",
        "Dermatologue",
        "Gynécologue",
        "Pédiatre",
    ];

    const cost = [
        { "speciality": "Médecin Généraliste", "cost": [15000] },
        { "speciality": "Pédiatre", "cost": [34500] },
        { "speciality": "Cardiologue", "cost": [34404] },
        { "speciality": "Dentiste", "cost": [20694] },
        { "speciality": "Dermatologue", "cost": [20694] },
        { "speciality": "Gynécologue", "cost": [20694] },
        { "speciality": "Neurologue", "cost": [33896] },
        { "speciality": "Neuropsychiatre", "cost": [33896] },
        { "speciality": "Ophtalmologue", "cost": [20694] },
        { "speciality": "Oto-rhino-laryngologiste (ORL)", "cost": [20694] },
        { "speciality": "Orthopédiste", "cost": [20694] },
        { "speciality": "Pneumologue", "cost": [20694] },
        { "speciality": "Psychiatre", "cost": [33896] },
        { "speciality": "Rhumatologue", "cost": [20694] },
        { "speciality": "Urologue", "cost": [20694] }
    ];
      

    const [counter, setCounter] = useState(0);
    const [isChange, setIsChange] = useState(false);
    const [RDVDate, setRDVDate] = useState("");
    const [user, setUser] = useState({
        ID: "",
        nom : "",
        prenom : "",
        email : "",
        date_naissance: "",
        numero_telephone: "",
    });

    const portefolioImageList = [
        {
            src: 'back1.jpg',
            text: [],
            src_: '/upload/images/back1.jpg',
            text_: "Description de l'image"
        },
        {
            src: 'back2.jpg',
            text: [],
            src_: '/upload/images/back2.jpg',
            text_: "Description de l'image"
        },
        {
            src: 'back3.jpg',
            text: [],
            src_: '/upload/images/back3.jpg',
            text_: "Description de l'image"
        },
    ];

    const servicesList = [
        {
            logo: teleconsult,
            name: "Téléconsultation Médicale",
            description: "Ilera offre un service de téléconsultation, permettant aux utilisateurs de consulter des professionnels de la santé depuis le confort de leur domicile. Une approche novatrice éliminant les barrières physiques, offrant un accès instantané aux conseils médicaux.",
        },

        {
            logo: discuss,
            name: "Connexion sécurisée",
            description: "En parallèle, notre plateforme agit comme un pont virtuel entre les patients et les professionnels de la santé, facilitant une communication fluide et une coordination efficace des soins. Cette connectivité renforcée contribue à une meilleure prise en charge médicale.",
        },

        {
            logo: partage,
            name: "Partage de connaissances",
            description: "Ilera se distingue par sa mission de favoriser le partage des connaissances médicales. En facilitant l'échange d'informations entre les professionnels de la santé, notre plateforme contribue à une base de connaissances plus étendue et actualisée.",
        }
    ];

    const formatResult = (item) => {
        return (
            <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    const formatResult_adress = (item) => {
        return (
            <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    useEffect(() => {
        window.onload = animer.start();
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        if(currentUser!==null) {
            setUser(currentUser);
        }
        USR.get_specialite_list().then((res) => {
            setSpecialite(res.data.specialites.map((sp)=>{
                return {name: sp};
            }));
            USR.get_doctor_list().then((res) => {
                setSpecialite((prev)=>{
                    return( prev.concat(
                        res.data.medecins.map((md)=>{
                            return {name: md.nom + " " + md.prenom};
                        })
                    ))
                });
                // console.log(res.data);
                // res.data.specialites.map((sp)=>{
                //     console.log(sp);
                // });
            });
        });
        return () => {
            animer.stop();
        }
    },[]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsChange(true);
            const timeout = setTimeout(() => {
                setCounter(prevCounter => (prevCounter < specialities.length - 1 ? prevCounter + 1 : 0));
                setIsChange(false);
                clearInterval(timeout);
            }, 1000);
        }, 5000);
        return () => clearInterval(intervalId);
    });

    function MakeRequest(data) {
        localStorage.setItem("ilera-patient-dataForRequest", JSON.stringify(data));
        window.location.href = "/search/" + data.specialite;
        // setIsLoading(true);
        // console.log(data);
        // USR.makeRequest(data).then((response) => {
        //     setIsLoading(false);
        //     let res = response.data;
        //     // console.log(res);
        //     if(res.success === true) {
        //         localStorage.setItem("ilera-patient-request", JSON.stringify(res));
        //         alert("Votre demande a bien été prise en compte. Veuillez maintenant choisir un tarif et procéder au paiement.");
        //     } else {
        //         if(res.data.error === "Veuillez fournir votre ID utilisateur.") {
        //             alert("Veuillez vous connecter svp!");
        //             window.location.href = "/login";
        //         }
        //     }
        // }).catch((e) => {
        //     if(e.response.data.error === "Veuillez fournir votre ID utilisateur.") {
        //         alert("Veuillez vous connecter svp!");
        //         window.location.href = "/login";
        //     }
        //     // console.log(e.response);
        //     setIsLoading(false);
        // });
    }

    return (
        <div className='homeViewContent'>
            <section className="homeViewContent_section1">
                <div className="homeViewContent_section1_prtfl">
                    <ImageSlider1
                        delay="5000"
                        onClickText={(text) => {}}
                        imageList={portefolioImageList}
                        anotherContent={() => {
                            return (
                                <div>
                                    {/* <table className='grid'>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table> */}

                                    
                                </div>
                            )
                        }}
                    />
                </div>

                <div className='homeViewContent_section1_prtfl_presentation'>
                    <h3>La téléconsultation<br/> sans frontières</h3>
                    <div className='homeViewContent_content_title'>
                        <h4 className='block-title text-center'>
                            <span>Trouvez un rendez-vous avec un</span> <span className='changer_pre'><a
                                onClick={(e) => {
                                    e.preventDefault();
                                    let data = {
                                        "utilisateur_id": user.ID,
                                        "adresse": searchAddress,
                                        "specialite": specialities[counter],
                                        "nom": "",
                                        "prenom": "",
                                        "date_naissance": "",
                                        "email": "",
                                        "numero_telephone": "",
                                        "genre": "",
                                        "date_rdv": "",
                                        "heure_debut": "",
                                        "motif_consultation": ""
                                    };
                                    MakeRequest(data);
                                }}
                            href={"/search/"+specialities[counter]} className='changer' style={{height: isChange ? "0px" : "50px"}}>{specialities[counter]}</a></span>
                        </h4>
                    </div>
                    <form className='homeViewContent_content_search' method='post' action='#' name="RDVRequest"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if(searchValue!=="") {
                                let data = {
                                    "utilisateur_id": user.ID,
                                    "adresse": searchAddress,
                                    "specialite": searchValue,
                                    "nom": "",
                                    "prenom": "",
                                    "date_naissance": "",
                                    "email": "",
                                    "numero_telephone": "",
                                    "genre": "",
                                    "date_rdv": "",
                                    "heure_debut": "",
                                    "motif_consultation": ""
                                };
                                // console.log(data);
                                MakeRequest(data);
                            }
                        }}
                    >
                        <div className='homeViewContent_content_search_input name'>
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                            </svg>
                            {/* <input type='search' name='searchInfo' placeholder='Nom, généraliste, spécialité, etc...'/> */}
                            <ReactSearchAutocomplete
                                items={specialite}
                                onSearch={(string, results)=>{
                                    setSearchValue(string);
                                }}
                                // onHover={handleOnHover}
                                onSelect={(item)=>{
                                    setSearchValue(item.name);
                                }}
                                // onFocus={handleOnFocus}
                                // autoFocus
                                formatResult={formatResult}
                                // inputSearchString={searchValue}
                                placeholder='Nom, généraliste, spécialité, etc...'
                                className="homeViewContent_content_search_input_balise"
                                showIcon={false}
                                styling={{
                                    borderRadius: "0px",
                                    margin: "0px",
                                    boxShadow: "0px 0px 0px 0px #00000000",
                                    borderColor: "transparent",
                                    height: "50px",
                                }}
                            />
                        </div>
                        <div className='homeViewContent_content_search_input location'>
                            <svg width="1em" height="1em" viewBox="-4 0 32 32" version="1.1" >
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                                    <g id="Icon-Set" transform="translate(-104.000000, -411.000000)" fill="currentColor">
                                        <path d="M116,426 C114.343,426 113,424.657 113,423 C113,421.343 114.343,420 116,420 C117.657,420 119,421.343 119,423 C119,424.657 117.657,426 116,426 L116,426 Z M116,418 C113.239,418 111,420.238 111,423 C111,425.762 113.239,428 116,428 C118.761,428 121,425.762 121,423 C121,420.238 118.761,418 116,418 L116,418 Z M116,440 C114.337,440.009 106,427.181 106,423 C106,417.478 110.477,413 116,413 C121.523,413 126,417.478 126,423 C126,427.125 117.637,440.009 116,440 L116,440 Z M116,411 C109.373,411 104,416.373 104,423 C104,428.018 114.005,443.011 116,443 C117.964,443.011 128,427.95 128,423 C128,416.373 122.627,411 116,411 L116,411 Z" id="location"></path>
                                    </g>
                                </g>
                            </svg>
                            {/* <input type='search' name='searchAddress' placeholder='Où'/> */}
                            <ReactSearchAutocomplete
                                items={address}
                                onSearch={(string, results)=>{
                                    setSearchAddress(string);
                                    // console.log(string);
                                }}
                                // onHover={handleOnHover}
                                onSelect={(item)=>{
                                    setSearchAddress(item.name);
                                }}
                                // onFocus={handleOnFocus}
                                formatResult={formatResult_adress}
                                // inputSearchString={searchAddress}
                                placeholder='Où'
                                className="homeViewContent_content_search_input_balise"
                                showIcon={false}
                                styling={{
                                    borderRadius: "0px",
                                    margin: "0px",
                                    boxShadow: "0px 0px 0px 0px #00000000",
                                    borderColor: "transparent",
                                    height: "50px",
                                }}
                            />
                        </div>
                        <div className='homeViewContent_content_search_button'>
                            <button>
                                Rechercher
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            <section className="section2">
                <div className="section2_title x-animation"
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <span className="title-style-1">
                        Nous proposons
                    </span>
                </div>

                <div className="section2_subtitle x-animation"
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <h3>
                        {/* <span></span> */}
                        Un système intelligent de prise en charge médicale
                    </h3>
                </div>

                <div className="section2_list">
                    {
                        servicesList.map((service, index) => (
                            <div className="section2_list_elt x-animation"
                                x-animation="from-bottom"
                                x-animation-delay="0s"
                                x-animation-duration={((index + 1) * 300) + "ms"}
                            >
                                <Card elt={service} link="##" />
                            </div>
                        ))
                    }
                </div>
            </section>

            <section className='section2'>
                <div className='section2_icon x-animation'
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <img src={ilera_logo_small} alt='ilera'/>
                </div>

                <div className='section2_paragraph x-animation'
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <p>
                        Ilera s'engage à transformer l'expérience des soins de santé en combinant la praticité des téléconsultations, la connectivité entre patients et professionnels de la santé, et la promotion active du partage des connaissances. Cette approche holistique vise à améliorer significativement l'accessibilité, l'efficacité et la qualité des soins médicaux pour tous.
                    </p>
                </div>
            </section>

            <section className="section2">
                <div className="section2_title x-animation"
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <span className="title-style-1">
                        Comment ça marche
                    </span>
                </div>

                <div className="section2_subtitle x-animation"
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <h3>
                        {/* <span></span> */}
                        La plateforme
                    </h3>
                </div>

                <div className="section2_steps">
                    <img src={lines} className="section2_steps_line" alt=""/>
                    <div className="section2_steps_list">
                        <div className="steps">
                            <div className="steps_ll step1">
                                <div className="steps_count">
                                    <span>01</span>
                                </div>
                                <h3 className="text-center"><a href="##">Demande rapide de soin<br/>et<br/> Authentification</a></h3>
                            </div>
                        </div>
                        <div className="steps two">
                            <div className="steps_ll step2">
                                <div className="steps_count yellow">
                                    <span>02</span>
                                </div>
                                <h3 className="text-center"><a href="##">Souscrire à la plateforme <br/> ilera </a></h3>
                            </div>
                        </div>
                        <div className="steps">
                            <div className="steps_ll step3">
                                <div className="steps_count green">
                                    <span>03</span>
                                </div>
                                <h3 className="text-center"><a href="##">Réaliser une <br/>consultations  <br/></a></h3>
                            </div>
                        </div>
                        <div className="steps two">
                            <div className="steps_ll step4">
                                <div className="steps_count red">
                                    <span>04</span>
                                </div>
                                <h3 className="text-center"><a href="##">Prise en charge<br/> du patient <br/></a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section2">
                <div className="section2_title x-animation"
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <span className="title-style-1">
                        Effectuer une requête de soin
                    </span>
                </div>

                <div className='section2_form x-animation'
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <form action='#' method='POST' className='form' name='request'
                        onSubmit={(e) => {
                            e.preventDefault();

                            // let date_rdv = new Date(RDVDate);
                            // let year_ = date_rdv.getFullYear();
                            // let month_ = (date_rdv.getMonth() + 1).toString().padStart(2, '0'); // Mois indexé à partir de zéro
                            // let day_ = date_rdv.getDate().toString().padStart(2, '0');
                            
                            let data = {
                                "utilisateur_id": user.ID,
                                "nom": user.nom,
                                "prenom": user.prenom,
                                "date_naissance": document.forms["request"]["borne_month"].value,
                                "email": user.email,
                                "numero_telephone": user.numero_telephone,
                                "genre": document.forms["request"]["sex"].value,
                                "adresse": searchAddress,
                                "date_rdv": RDVDate,
                                // "date_rdv": `${year_}-${month_}-${day_}`,
                                "heure_debut": document.forms["request"]["heure_debut"].value,
                                "specialite": searchValue,
                                "motif_consultation": document.forms["request"]["motif_consultation"].value
                            }
                            MakeRequest(data);
                        }}
                    >
                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Nom</span>
                                </div>
                                <div className='form_input_balise'>
                                    <input value={user.nom} name='lastName' required placeholder='nom' type='text'
                                        onChange={(e)=>{
                                            setUser({
                                                ...user,
                                                nom: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                                <div className='form_input_subText'>
                                    <span>Votre nom</span>
                                </div>
                            </div>

                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Prénom</span>
                                </div>
                                <div className='form_input_balise'>
                                    <input value={user.prenom} name='firstName' required placeholder='Prénom' type='text'
                                        onChange={(e)=>{
                                            setUser({
                                                ...user,
                                                prenom: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                                <div className='form_input_subText'>
                                    <span>Votre prénom</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Numéro de téléphone</span>
                                </div>
                                <div className='form_input_balise'>
                                    <input value={user.numero_telephone} name='phoneNumber' placeholder='' type='tel'
                                        onChange={(e)=>{
                                            setUser({
                                                ...user,
                                                numero_telephone: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form_input_subText'>
                                    <span>Votre numéro</span>
                                </div>
                            </div>

                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Email</span>
                                </div>
                                <div className='form_input_balise'>
                                    <input value={user.email} required name='email' placeholder='' type='email'
                                        onChange={(e)=>{
                                            setUser({
                                                ...user,
                                                email: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                                <div className='form_input_subText'>
                                    <span>Votre email</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Sexe à l'état civil</span>
                                </div>
                                <div className='form_input_balise'>
                                    <input name='sex' value="Féminin" id='female' type='radio'/>
                                    <label htmlFor="female">Féminin</label>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span></span>
                                </div>
                                <div className='form_input_balise'>
                                    <input name='sex' value="Masculin" id='mal' type='radio'/>
                                    <label htmlFor="mal">Masculin</label>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        <br/>
                        <br/>

                        <div className='flex'>
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Date de naissance</span>
                                </div>
                                <div className='form_input_balise'>
                                    <input name='borne_month' required placeholder='dd/mm/yy' type='date'/>
                                </div>
                                <div className='form_input_subText'>
                                    {/* <span>Âge: <strong></strong></span> */}
                                </div>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Adresse</span>
                                </div>
                                <ReactSearchAutocomplete
                                    items={address}
                                    onSearch={(string, results)=>{
                                        setSearchAddress(string);
                                        // console.log(string);
                                    }}
                                    // onHover={handleOnHover}
                                    onSelect={(item)=>{
                                        setSearchAddress(item.name);
                                    }}
                                    // onFocus={handleOnFocus}
                                    formatResult={formatResult_adress}
                                    // inputSearchString={searchAddress}
                                    placeholder='Où'
                                    className="homeViewContent_content_search_input_balise"
                                    showIcon={false}
                                    styling={{
                                        borderRadius: "0px",
                                        margin: "0px",
                                        boxShadow: "0px 0px 0px 0px #00000000",
                                        borderColor: "transparent",
                                        height: "50px",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Spécialité ou type de médecin recherché</span>
                                </div>
                                <ReactSearchAutocomplete
                                    items={specialite}
                                    onSearch={(string, results)=>{
                                        setSearchValue(string);
                                    }}
                                    // onHover={handleOnHover}
                                    onSelect={(item)=>{
                                        setSearchValue(item.name);
                                    }}
                                    // onFocus={handleOnFocus}
                                    // autoFocus
                                    formatResult={formatResult}
                                    // inputSearchString={searchValue}
                                    placeholder='Nom, généraliste, spécialité, etc...'
                                    className="homeViewContent_content_search_input_balise"
                                    showIcon={false}
                                    styling={{
                                        borderRadius: "0px",
                                        margin: "0px",
                                        boxShadow: "0px 0px 0px 0px #00000000",
                                        borderColor: "transparent",
                                        height: "50px",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Motif de la consultation</span>
                                </div>
                                <div className='form_input_balise'>
                                    <textarea name="motif_consultation" required/>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                            
                        </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Préférences de date et d'heure</span>
                                </div>
                                <div className='form_input_balise'>
                                    <div className='flex datePickerContent'>
                                        <div className='datePickerContent_'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateCalendar']}>
                                                    <DateCalendar
                                                        referenceDate={dayjs(new Date())}
                                                        views={['year', 'month', 'day']}
                                                        onChange={(e)=>{
                                                            setRDVDate(new Date(e.$d).toISOString());
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='form_input'>
                                            <div className='form_input_title'>
                                                <span></span>
                                            </div>
                                            <div className='form_input_balise'>
                                                <input name='heure_debut' placeholder='' type='time'/>
                                            </div>
                                            <div className='form_input_subText'>
                                                <span>Heure de préférence</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className='login_content_form_button'>
                            <button>
                                Soumettre
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            {
                viewTarif ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setViewTarif(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Sellectionnez le tarif qui vous convient le mieux
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <section className='table_content'>
                                    <table class="table table--bordered" align="center">
                                        <caption class="table__title">Tarifs de base d'une consultation pour les médecins spécialistes</caption>
                                        <thead class="table--secondary">
                                            <tr>
                                                <th>Médecins spécialistes</th>
                                                <th>Tarifs de consultation du médecin</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cost.map((ct, index) => (
                                                    <tr class="cell--center">
                                                        <th>{ct.speciality}</th>
                                                        <td>✔️ {ct.cost}xof</td>
                                                        <td>
                                                            <button
                                                                className='tab-button-1'
                                                                onClick={(e) => {
                                                                    window.location.href =  PATH__.api_root + 'paiement/paiement_facture/'+user.ID+'/'+ct.cost+'/'+ct.speciality+"/";
                                                                    // window.location.href =  '/payment?coast='+ct.cost+'&speciality='+ct.speciality;
                                                                }}
                                                            >
                                                                Choisir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </section>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);