// import React, {useState, useEffect} from 'react';
import React, {useEffect, useState} from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import HomeView from './homeView.screen.jsx';
import AboutIlera from './aboutIlera.screen.jsx';
import FaqIlera from './faqIlera.screen copy.jsx';
import ContactIlera from './contactIlera.screen.jsx';
import TarifView from './tarifView.screen.jsx';
import PaymentsView from './paymentsView.screen.jsx';
import PublicMeeting from './public.meeting.screen.jsx';
import SearchDoctorView from './searchDoctor.screen.jsx';


/**Importation des composants */
import MenuComponent from '../components/menu/menu.component';

import '../styles/home.screen.style.scss';

import ilera from '../assets/images/ilera-logo-white.png';
import ilera_ from '../assets/images/ilera_logo.png';

/**Importation des helpers */
// import FixedOnScrollPage from '../helpers/fixe_to_head.helper';

function Home( props ) {

    const [user, setUser] = useState(null);
    // const currentPath = window.location.pathname;

    // const [isHidden, setIsHidden] = useState(false);
    // function handleScroll() {
    //     const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     if (currentScrollTop > 50) {
    //         // Scrolling down and passed the threshold
    //         setIsHidden(true);
    //     } else {
    //         // Scrolling up and passed the threshold
    //         setIsHidden(false);
    //     }
    // }

    useEffect(()=>{
        // window.addEventListener('scroll', handleScroll);
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        setUser(currentUser);
    },[]);

    const list_menu = [{
        // icon: <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" >
        //     <g clip-path="url(#clip0)">
        //     <path d="M15.783 13.4262C15.2149 12.902 14.5259 12.4491 13.735 12.0801C13.3969 11.9224 13.0013 12.0823 12.8511 12.4371C12.701 12.792 12.8532 13.2074 13.1912 13.3652C13.8581 13.6764 14.4328 14.0523 14.899 14.4825C15.4739 15.0129 15.8036 15.7808 15.8036 16.5898V17.8906C15.8036 18.2783 15.5031 18.5938 15.1339 18.5938H2.00893C1.63971 18.5938 1.33929 18.2783 1.33929 17.8906V16.5898C1.33929 15.7808 1.66901 15.0129 2.24383 14.4825C2.9204 13.8582 4.89166 12.4062 8.57143 12.4062C11.3038 12.4062 13.5268 10.0721 13.5268 7.20312C13.5268 4.33418 11.3038 2 8.57143 2C5.8391 2 3.61607 4.33418 3.61607 7.20312C3.61607 8.88033 4.37596 10.3745 5.55307 11.3266C3.39922 11.8237 2.05941 12.7806 1.35982 13.4262C0.495693 14.2234 0 15.3764 0 16.5898V17.8906C0 19.0538 0.90114 20 2.00893 20H15.1339C16.2417 20 17.1429 19.0538 17.1429 17.8906V16.5898C17.1429 15.3764 16.6472 14.2234 15.783 13.4262ZM4.95536 7.20312C4.95536 5.10954 6.57754 3.40625 8.57143 3.40625C10.5653 3.40625 12.1875 5.10954 12.1875 7.20312C12.1875 9.29671 10.5653 11 8.57143 11C6.57754 11 4.95536 9.29671 4.95536 7.20312Z" fill="#353D40aa"/>
        //     </g>
        //     <path d="M15.8335 1.875C15.5048 1.875 15.2383 2.15481 15.2383 2.5C15.2383 2.84519 15.5048 3.125 15.8335 3.125H17.024V4.375C17.024 4.72019 17.2905 5 17.6192 5C17.948 5 18.2145 4.72019 18.2145 4.375V3.125H19.4049C19.7337 3.125 20.0002 2.84519 20.0002 2.5C20.0002 2.15481 19.7337 1.875 19.4049 1.875H18.2145V0.625C18.2145 0.279812 17.948 0 17.6192 0C17.2905 0 17.024 0.279812 17.024 0.625V1.875H15.8335Z" fill="#353D40aa"/>
        //     <defs>
        //     <clipPath id="clip0">
        //     <rect width="17.1429" height="18" fill="white" transform="translate(0 2)"/>
        //     </clipPath>
        //     </defs>
        // </svg>,
        icon: null,
        name: "S'inscrire",
        link: "/registration",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        // icon: <svg fill="#353D40aa" width="1em" height="1em" viewBox="0 0 1920 1920">
        //     <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
        // </svg>,
        icon: null,
        name: "Se connecter",
        link: "/login",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        // icon: <svg fill="#faaa68" width="1em" height="1em" viewBox="0 0 14 14" role="img" focusable="false" aria-hidden="true"><path d="m 1.4358383,12.895149 c -0.38924,-0.1993 -0.43281,-0.3751 -0.41279,-1.665 0.0158,-1.0195 0.0314,-1.1697 0.14735,-1.4205004 0.26312,-0.5694 0.88589,-1.0631 1.46037,-1.1579 l 0.21,-0.035 0.62728,1.0597 c 0.345,0.5829004 0.63415,1.0358004 0.64256,1.0065004 0.008,-0.029 0.0415,-0.3472 0.0736,-0.7065004 0.0525,-0.5882 0.0468,-0.6826 -0.0575,-0.9472 -0.0637,-0.1617 -0.11586,-0.32 -0.11586,-0.3517 0,-0.033 0.26499,-0.058 0.62678,-0.058 l 0.62678,0 -0.12063,0.345 c -0.10456,0.2991 -0.11523,0.4309 -0.0802,0.9905 0.0222,0.3551004 0.0611,0.6584004 0.0864,0.6740004 0.0253,0.016 0.32158,-0.4318 0.65846,-0.9942004 l 0.61252,-1.0226 0.18995,0.04 c 0.58752,0.1229 1.19054,0.5979 1.42811,1.1248 0.12949,0.2872004 0.1425,0.4062004 0.1588,1.4528004 0.0156,0.9994 0.005,1.1659 -0.0896,1.35 -0.21721,0.425 -0.1746,0.4199 -3.4974,0.4199 -2.70948,0 -2.98802,-0.01 -3.17496,-0.1049 z m 2.71495,-4.9624004 c -0.76247,-0.3317 -1.3488,-1.7133 -1.07583,-2.5352 0.48535,-1.4612 2.58633,-1.4612 3.07167,0 0.27559,0.8297 -0.31945,2.2162 -1.08916,2.5378 -0.25463,0.1064 -0.65878,0.1053 -0.90668,0 z m 3.58006,-2.0213 0,-0.6365 -0.34018,-0.028 c -0.27583,-0.023 -0.36661,-0.059 -0.48,-0.1908 -0.13962,-0.1623 -0.13984,-0.1653 -0.13984,-1.9339 l 0,-1.7713 0.17539,-0.1754 0.17539,-0.1753 2.76131,0 2.7613097,0 0.16835,0.1448 0.16835,0.1448 0,1.8101 0,1.8102 -0.19595,0.175 -0.19595,0.1751 -1.7831,0 -1.7830997,0 -0.64599,0.644 -0.64599,0.644 0,-0.6366 z"/></svg>,
        icon: null,
        name: "Témoignage",
        link: "##",
        action: (callback) => {
            callback();
            return true;
        },
    },
    {
        // icon: <svg width="1em" height="1em" viewBox="0 -32 1088 1088" fill="#faaa68" class="icon"  version="1.1"><path d="M992 64H768c-52.8 0-126.546 30.546-163.882 67.882L227.882 508.118c-37.334 37.334-37.334 98.428 0 135.764l280.236 280.232c37.334 37.336 98.428 37.336 135.764 0l376.232-376.232C1057.454 510.546 1088 436.8 1088 384V160c0-52.8-43.2-96-96-96z m-128 320c-53.02 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96zM86.626 598.624l342.378 342.378c-36.264 19.16-82.462 13.54-112.886-16.888L35.882 643.882c-37.334-37.336-37.334-98.43 0-135.764L412.118 131.882C449.454 94.546 523.2 64 576 64L86.626 553.372c-12.444 12.446-12.444 32.808 0 45.252z" /></svg>,
        icon: null,
        name: "Tarifs",
        link: "/tarifs",
        action: (callback) => {
            callback();
            return true;
        },
    },
    {
        // icon: <svg fill="#faaa68" width="1em" height="1em" viewBox="0 0 24 24"><path d="M12,1A11,11,0,1,0,23,12,11.013,11.013,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9.011,9.011,0,0,1,12,21Zm1-4.5v2H11v-2Zm3-7a3.984,3.984,0,0,1-1.5,3.122A3.862,3.862,0,0,0,13.063,15H11.031a5.813,5.813,0,0,1,2.219-3.936A2,2,0,0,0,13.1,7.832a2.057,2.057,0,0,0-2-.14A1.939,1.939,0,0,0,10,9.5,1,1,0,0,1,8,9.5V9.5a3.909,3.909,0,0,1,2.319-3.647,4.061,4.061,0,0,1,3.889.315A4,4,0,0,1,16,9.5Z"/></svg>,
        icon: null,
        name: "FAQ",
        link: "/faq",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        // icon: <svg width="1em" height="1em" viewBox="0 0 512 512" version="1.1">
        //         <title>about</title>
        //         <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        //             <g id="about-white" fill="#faaa68" transform="translate(42.666667, 42.666667)">
        //                 <path d="M213.333333,3.55271368e-14 C95.51296,3.55271368e-14 3.55271368e-14,95.51168 3.55271368e-14,213.333333 C3.55271368e-14,331.153707 95.51296,426.666667 213.333333,426.666667 C331.154987,426.666667 426.666667,331.153707 426.666667,213.333333 C426.666667,95.51168 331.154987,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,384 C119.227947,384 42.6666667,307.43872 42.6666667,213.333333 C42.6666667,119.227947 119.227947,42.6666667 213.333333,42.6666667 C307.44,42.6666667 384,119.227947 384,213.333333 C384,307.43872 307.44,384 213.333333,384 Z M240.04672,128 C240.04672,143.46752 228.785067,154.666667 213.55008,154.666667 C197.698773,154.666667 186.713387,143.46752 186.713387,127.704107 C186.713387,112.5536 197.99616,101.333333 213.55008,101.333333 C228.785067,101.333333 240.04672,112.5536 240.04672,128 Z M192.04672,192 L234.713387,192 L234.713387,320 L192.04672,320 L192.04672,192 Z" id="Shape"></path>
        //             </g>
        //         </g>
        //     </svg>,
        icon: null,
        name: "À propos",
        link: "/about-ilera",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        // icon: <svg width="1em" height="1em" viewBox="0 0 512 512" version="1.1">
        //         <title>about</title>
        //         <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        //             <g id="about-white" fill="#faaa68" transform="translate(42.666667, 42.666667)">
        //                 <path d="M213.333333,3.55271368e-14 C95.51296,3.55271368e-14 3.55271368e-14,95.51168 3.55271368e-14,213.333333 C3.55271368e-14,331.153707 95.51296,426.666667 213.333333,426.666667 C331.154987,426.666667 426.666667,331.153707 426.666667,213.333333 C426.666667,95.51168 331.154987,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,384 C119.227947,384 42.6666667,307.43872 42.6666667,213.333333 C42.6666667,119.227947 119.227947,42.6666667 213.333333,42.6666667 C307.44,42.6666667 384,119.227947 384,213.333333 C384,307.43872 307.44,384 213.333333,384 Z M240.04672,128 C240.04672,143.46752 228.785067,154.666667 213.55008,154.666667 C197.698773,154.666667 186.713387,143.46752 186.713387,127.704107 C186.713387,112.5536 197.99616,101.333333 213.55008,101.333333 C228.785067,101.333333 240.04672,112.5536 240.04672,128 Z M192.04672,192 L234.713387,192 L234.713387,320 L192.04672,320 L192.04672,192 Z" id="Shape"></path>
        //             </g>
        //         </g>
        //     </svg>,
        icon: null,
        name: "Spécialistes",
        link: "/search/specialistes",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        // icon: <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
        //     <path opacity="0.1" d="M8.976 3C4.05476 3 3 4.05476 3 8.976V15.024C3 19.9452 4.05476 21 8.976 21H9V9H21V8.976C21 4.05476 19.9452 3 15.024 3H8.976Z" fill="#323232"/>
        //     <path d="M3 8.976C3 4.05476 4.05476 3 8.976 3H15.024C19.9452 3 21 4.05476 21 8.976V15.024C21 19.9452 19.9452 21 15.024 21H8.976C4.05476 21 3 19.9452 3 15.024V8.976Z" stroke="#323232" stroke-width="2"/>
        //     <path d="M21 9L3 9" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //     <path d="M9 21L9 9" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        // </svg>,
        icon: null,
        name: "Tableau de bord",
        link: user!==null ? ( user.role === "administrateur" ? "/dashboard/admin" : ((user.role === "Utilisateur standard" || user.role === "patient" || user.role === "Patient") ? "/dashboard/patient" : "/dashboard/doctor" )) : "",
        action: (callback) => {
            callback();
            return true;
        },
    }];
    // useEffect(() => {
    //     const currentUser = JSON.parse(localStorage.getItem("user"));
    // },[]);
    return (
        <>
            <header>
                <div className="nav kando-header bottom_nav">
                    <div>
                        <a href='/'><img src={ilera_} alt="ilera" /></a>
                    </div>
                    <MenuComponent list_menu={list_menu} />
                </div>
            </header>
            <Routes>
                <Route path='/about-ilera' element={<AboutIlera/>}/>
                <Route path='/contact-us' element={<ContactIlera/>}/>
                <Route path='/faq' element={<FaqIlera/>}/>
                <Route path='/tarifs' element={<TarifView/>}/>
                <Route path='/payment' element={<PaymentsView/>}/>
                <Route path='/meeting' element={<PublicMeeting/>}/>
                <Route path='/search/:type' element={<SearchDoctorView/>}/>
                <Route path='*' element={<HomeView/>}/>
            </Routes>
            <footer className="footer">
                <div className="site-footer__upper">
                    <div className="container">
                        <div className="footer-row">
                            <div className="footer-row-content">
                                <div className="footer-widget footer-widget__about">
                                    <h3 className="footer-widget__title"><img className="ilera-logo-footer" src={ilera} alt=''/></h3>
                                    <p>Nous faisons de votre santé et de votre Bien-être notre priorité.</p>
                                    <div className="footer-widget__social">
                                        <a href="##"><i className="fa fa-facebook-f"></i></a>
                                        <a href="##"><i className="fa fa-twitter"></i></a>
                                        <a href="##"><i className="fa fa-google"></i></a>
                                        <a href="##"><i className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-row-content">
                                <div className="footer-widget footer-widget__links__2">
                                    <h3 className="footer-widget__title">Liens utiles</h3>
                                    <ul className="list-unstyled footer-widget__links-list">
                                        <li><a href="/about-ilera"><span className="fa fa-chevron-right mr-2"></span>À propos</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Conditions générales</a></li>
                                        {
                                            user===null ?
                                                <>
                                                    <li><a href="/registration"><span className="fa fa-chevron-right mr-2"></span>S'inscrire</a></li>
                                                    <li><a href="/login"><span className="fa fa-chevron-right mr-2"></span>Se connecter</a></li>
                                                </>
                                            : <li><a href={user.role === "Administrateur" ? "/dashboard/admin" : ((user.role === "Utilisateur standard" || user.role === "patient") ? "/dashboard/patient" : "/dashboard/doctor")}><span className="fa fa-chevron-right mr-2"></span>Tableau de bord</a></li>
                                        }
                                        
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Témoignages</a></li>
                                        <li><a href="/tarifs"><span className="fa fa-chevron-right mr-2"></span>Tarifs</a></li>
                                        <li><a href="/faq"><span className="fa fa-chevron-right mr-2"></span>FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-row-content">
                                <div className="footer-contacts footer-widget footer-widget__contact">
                                    <h3 className="footer-widget__title">Contact</h3>
                                    <p><i className="fa fa-map-marker"></i>Lot: 2019 - Maison colonel BOSSOU Emmanuel<br/> Zogbohouè - Cotonou</p>
                                    <p><a href="mailto:welcome@ilera.com"><i className="fa fa-envelope"></i>contact@ilera.tech</a></p>
                                    <p><a href="tel:#"><i className="fa fa-phone"></i>(229) 97 58 27 87</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer__bottom_">
                    <div className="container">
                        {/* <p></p> */}
                        <a className="allright" href="./"><span>© 2023 </span><img className="ilera-logo-footer-2" src={ilera} alt=''/> <span> Tous droits réservés</span></a>
                        <ul className="list-unstyled site-footer__bottom-menu confidential">
                            <li><a className="mt-2" href="##">Politique de confidentialité</a></li>
                            <li>
                               
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home;