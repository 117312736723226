import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import '../styles/dashboard.agenda.style.scss';

import CalendarRequest from '../helpers/calendar.requests.helper';

import loader from '../assets/gif/loader.gif';

import AuthRequest from '../helpers/auth.requests.helper';

const Auth = new AuthRequest();

function renderEventContent(eventInfo) {
    // console.log(eventInfo)
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <p className='eventTitle'>{eventInfo.event.title}</p>
      </>
    )
}

const session = JSON.parse(localStorage.getItem("ilera"));
const CalendarRequest_ = new CalendarRequest();
// console.log(session);
function DashboardDoctorAGenda( props ) {
    const [current_event, setCurrent_event] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [eventList_, setEventList_] = useState([]);

    function addEvent() {
        let user_login = {
            username : session.username,
            password : password,
        };
        Auth.login(user_login).then((response) => {
            if(response.data.user_info && response.data.user_info.role) {
                setIsLoading(true);
                let event = {
                    titre: current_event.name,
                    date_debut: new Date(current_event.date_debut + "T" + current_event.start_time),
                    date_fin: new Date(current_event.date_fin + "T" + current_event.end_time),
                    description: current_event.description,
                };
                console.log(event);
                CalendarRequest_.add(session.ID, event).then((response) => {
                    setIsLoading(false);
                    let res = response.data;
                    // console.log(response);
                    if(res.success === true) {
                        alert(res.message);
                        window.location.reload();
                    } else {
                        if(response.data.error) {
                            alert(response.data.error);
                        }
                    }
                }).catch((e) => {
                    setIsLoading(false);
                });
            }
        });
    }

    function getEventList() {
        // console.log(currentUser);
        setIsLoading(true);
        CalendarRequest_.get(session.ID).then((response) => {
            setIsLoading(false);
            setEventList(response.data.user_events.map(item => ({
                title: item.description,
                date: item.date_debut.split('T')[0]
            })));
            setEventList_(response.data.user_events);
            console.log(response);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getEventList();
    },[]);

    return (
        <div className='agenda'>
            {/* <div>
                Agenda
            </div> */}

            <div className='agenda_content'>
                <div className='agenda_content_addPlan'>
                    <div className='agenda_content_addPlan_drag'>
                        {
                            eventList_.map((event)=>(
                                <div className='agenda_content_addPlan_drag_content'>
                                    <div className='agenda_content_addPlan_drag_content_description'>
                                        {event.description}
                                    </div>
                                    <div className='agenda_content_addPlan_drag_content_start'>
                                        Date de début: {event.date_debut.split('T')[0]} à {event.date_debut.split('T')[1]}
                                    </div>
                                    <div className='agenda_content_addPlan_drag_content_end'>
                                        Date de fin: {event.date_fin.split('T')[0]} à {event.date_fin.split('T')[1]}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='agenda_content_addPlan_button'>
                        <button
                            onClick={(e) => {
                                setCurrent_event({
                                    name: "",
                                    date_debut: "",
                                    start_time: "",
                                    date_fin: "",
                                    end_time: "",
                                    description: "",
                                });
                            }}
                        >
                            Ajouter un événement
                        </button>
                    </div>
                </div>

                <div className='agenda_content_viewPlan'>
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        eventContent={renderEventContent}
                        events={
                            eventList
                        }
                        dayHeaderFormat={{ weekday: 'short'}}
                    />
                </div>
            </div>

            {
                current_event !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrent_event(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                                <div></div>

                                <div className="prescription_list_title_export">
                                    <button>
                                        <span>PDF</span>

                                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                            <g fill-rule="evenodd">
                                                <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                                                <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
                                            </g>
                                        </svg>
                                    </button>
                                    <button>
                                        <span>Excel</span>

                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM19 12C19.5523 12 20 12.4477 20 13V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H19C18.4477 21 18 20.5523 18 20V13C18 12.4477 18.4477 12 19 12ZM11.8137 12.4188C11.4927 11.9693 10.8682 11.8653 10.4188 12.1863C9.96935 12.5073 9.86526 13.1318 10.1863 13.5812L12.2711 16.5L10.1863 19.4188C9.86526 19.8682 9.96935 20.4927 10.4188 20.8137C10.8682 21.1347 11.4927 21.0307 11.8137 20.5812L13.5 18.2205L15.1863 20.5812C15.5073 21.0307 16.1318 21.1347 16.5812 20.8137C17.0307 20.4927 17.1347 19.8682 16.8137 19.4188L14.7289 16.5L16.8137 13.5812C17.1347 13.1318 17.0307 12.5073 16.5812 12.1863C16.1318 11.8653 15.5073 11.9693 15.1863 12.4188L13.5 14.7795L11.8137 12.4188Z" fill="currentColor"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    addEvent();
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Nom</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={current_event.name} name='name' required placeholder='nom' type='text'
                                            onChange={(e)=>{
                                                setCurrent_event({
                                                    ...current_event,
                                                    name: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le nom de lévénement</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Date de début</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={current_event.date} name='start_date' required placeholder='date' type='date'
                                            onChange={(e)=>{
                                                setCurrent_event({
                                                    ...current_event,
                                                    date_debut: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le date de début de l'événement</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Heure de début</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={current_event.start_time} name='start_time' required placeholder='' type='time'
                                            onChange={(e)=>{
                                                setCurrent_event({
                                                    ...current_event,
                                                    start_time: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Date de fin</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={current_event.date} name='end_date' required placeholder='date' type='date'
                                            onChange={(e)=>{
                                                setCurrent_event({
                                                    ...current_event,
                                                    date_fin: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le date de fin de l'événement</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Heure de fin</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={current_event.end_time} name='end_time' required placeholder='' type='time'
                                            onChange={(e)=>{
                                                setCurrent_event({
                                                    ...current_event,
                                                    end_time: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Description</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <textarea value={current_event.description} name='description' required placeholder="Description de l'événement"
                                            onChange={(e)=>{
                                                setCurrent_event({
                                                    ...current_event,
                                                    description: e.target.value,
                                                })
                                            }}
                                        >

                                        </textarea>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Mot de passe</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input required value={password} name='password-mail' type='password'
                                            onChange={(e)=>{
                                                setPassword(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDoctorAGenda);