import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import '../styles/dashboard.appointment.style.scss';

const session = JSON.parse(localStorage.getItem("ilera"));


function DashboardPartnerProgram( props ) {
    const [current_doctor, setCurrent_doctor] = useState(null);
    const [doctorList, setDoctorList] = useState(null);

    useEffect(() => {
        setDoctorList([]);
    },[]);
        
    return (
        <div className='prescription'>
            <div className="prescription_list">
                <div className='prescription_list_head'>
                    <div className='prescription_list_head_left'>
                        <div className='prescription_list_head_left_content'>
                            <span>Filtrer par </span>
                            <select>
                                <option>
                                    Date
                                </option>
                                <option>
                                    Nom
                                </option>
                                <option>
                                    ID
                                </option>
                            </select> 
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className='prescription_list_head_left'>
                        <div className='prescription_list_head_left_content'>
                            <select>
                                <option>
                                    20
                                </option>
                                <option>
                                    50
                                </option>
                                <option>
                                    80
                                </option>
                                <option>
                                    120
                                </option>
                                <option>
                                    200
                                </option>
                                <option>
                                    500
                                </option>
                                <option>
                                    Tout
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className='prescription_list_head_right'>
                        <div className='prescription_list_head_right_content'>
                            <input type='search' placeholder='Rechercher...' />
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="prescription_list_title">
                    <span>Liste des programmes</span>
                    <div className="prescription_list_title_export">
                        <button>
                            <span>PDF</span>

                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                <g fill-rule="evenodd">
                                    <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                                    <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
                                </g>
                            </svg>
                        </button>
                        <button>
                            <span>Excel</span>

                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM19 12C19.5523 12 20 12.4477 20 13V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H19C18.4477 21 18 20.5523 18 20V13C18 12.4477 18.4477 12 19 12ZM11.8137 12.4188C11.4927 11.9693 10.8682 11.8653 10.4188 12.1863C9.96935 12.5073 9.86526 13.1318 10.1863 13.5812L12.2711 16.5L10.1863 19.4188C9.86526 19.8682 9.96935 20.4927 10.4188 20.8137C10.8682 21.1347 11.4927 21.0307 11.8137 20.5812L13.5 18.2205L15.1863 20.5812C15.5073 21.0307 16.1318 21.1347 16.5812 20.8137C17.0307 20.4927 17.1347 19.8682 16.8137 19.4188L14.7289 16.5L16.8137 13.5812C17.1347 13.1318 17.0307 12.5073 16.5812 12.1863C16.1318 11.8653 15.5073 11.9693 15.1863 12.4188L13.5 14.7795L11.8137 12.4188Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                    <a href='##'
                        onClick={(e) => {
                            setCurrent_doctor({
                                nom_medecin: "",
                                prenom_medecin: "",
                                role: "professionnel_sante",
                                username_medecin: "",
                                password_medecin: "ilera9023user",
                                email_medecin: "",
                                numero_medecin: "",
                                date_naissance: null,
                                adresse: "",
                                id_admin: session.id
                            });
                        }}
                    >
                        + Ajouter un programme
                    </a>
                </div>
                
                <div className="prescription_list_content">
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>Nom du programme</th>
                                <th>Réduction(%)</th>
                                <th>Statut</th>
                                <th>Date de Début</th>
                                <th>Date de Fin</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                doctorList !== null &&
                                doctorList.map((doctor, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><img src={doctor.photo_profil} alt='ilera'/></td>
                                        <td>{doctor.username}</td>
                                        <td>{doctor.specialite}</td>
                                        <td>{doctor.id}</td>
                                        <td>{doctor.numero_telephone}</td>
                                        <td>{doctor.patientNumber}</td>
                                        <td>{doctor.appointmentNumber}</td>
                                        <td>
                                            <button className='view'
                                                onClick={(e)=>{
                                                    
                                                }}
                                            >
                                                Valider
                                            </button>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setCurrent_doctor(doctor);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button className='delete'>
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>
            </div>

            {
                    current_doctor !== null ?
                        <section className='information'>
                            <div className='information_content'>
                                <div className='information_content_close'>
                                    <button onClick={(e) => {
                                        setCurrent_doctor(null);
                                    }}>
                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                            <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="information_content_head">
                                    <div className="information_content_head_id">
                                        ID: {current_doctor.ID}
                                    </div>

                                    <div></div>

                                    <div className="prescription_list_title_export">
                                        <button>
                                            <span>PDF</span>

                                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                                <g fill-rule="evenodd">
                                                    <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                                                    <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
                                                </g>
                                            </svg>
                                        </button>
                                        <button>
                                            <span>Excel</span>

                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM19 12C19.5523 12 20 12.4477 20 13V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H19C18.4477 21 18 20.5523 18 20V13C18 12.4477 18.4477 12 19 12ZM11.8137 12.4188C11.4927 11.9693 10.8682 11.8653 10.4188 12.1863C9.96935 12.5073 9.86526 13.1318 10.1863 13.5812L12.2711 16.5L10.1863 19.4188C9.86526 19.8682 9.96935 20.4927 10.4188 20.8137C10.8682 21.1347 11.4927 21.0307 11.8137 20.5812L13.5 18.2205L15.1863 20.5812C15.5073 21.0307 16.1318 21.1347 16.5812 20.8137C17.0307 20.4927 17.1347 19.8682 16.8137 19.4188L14.7289 16.5L16.8137 13.5812C17.1347 13.1318 17.0307 12.5073 16.5812 12.1863C16.1318 11.8653 15.5073 11.9693 15.1863 12.4188L13.5 14.7795L11.8137 12.4188Z" fill="currentColor"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <form action='#' method='POST' className='form'
                                    onSubmit={(e)=>{
                                        e.preventDefault();
                                    }}
                                >
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Nom</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={current_doctor.prenom_medecin} name='prenom_medecin' required placeholder='nom' type='text'
                                                onChange={(e)=>{
                                                    setCurrent_doctor({
                                                        ...current_doctor,
                                                        prenom_medecin: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Le nom du programme</span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Description du Programme</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <textarea name="" id="" cols="30" rows="10"></textarea>
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>L'email du partenaire</span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Réduction Offerte (%) :</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input name='prenom_medecin' type='email'/>
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Réduction Offerte (%) dans le cadre du programme</span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Date de Début du Programme</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input name='prenom_medecin' type='date'/>
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Date de Début du Programme</span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Date de Fin du Programme</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input name='prenom_medecin' type='date'/>
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>Date de Fin du Programme</span>
                                        </div>
                                    </div>

                                    <div className='login_content_form_input'>
                                        <div className='login_content_form_input_title'>
                                            <span>Programme source</span>
                                        </div>
                                        <div className='login_content_form_input_balise'>
                                            <select name="borne_day">
                                                <option>Programme 01</option>
                                                <option>Programme 02</option>
                                                <option>Programme 03</option>
                                                <option>Programme 04</option>
                                                <option>Programme 05</option>
                                                <option>Programme 06</option>
                                                <option>Programme 07</option>
                                                <option>Programme 08</option>
                                                <option>Programme 09</option>
                                                <option>Programme 10</option>
                                            </select>
                                        </div>
                                    </div>

                                    

                                    <div className='form_button'>
                                        <button>
                                            Envoyer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    :
                        null
                }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPartnerProgram);