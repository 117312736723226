import React, {useState} from 'react';
import { connect } from 'react-redux';

import '../styles/login.screen.style.scss';

/**Imortation des helpers */
import AuthRequest from '../helpers/auth.requests.helper';

/**Importation des images */
import ilera from '../assets/images/ilèra_logo.png'

import loader from '../assets/gif/loader.gif';

const Auth = new AuthRequest();

function LoginView( props ) {
    const [isLoading, setIsLoading] = useState(false);

    function login() {
        setIsLoading(true);
        let user = {
            username : document.forms["login"]["userName"].value,
            password : document.forms["login"]["password"].value,
        };
        Auth.login(user).then((response) => {
            setIsLoading(false);
            let user = response.data;
            console.log(response.data);
            if(user.user_info && user.user_info.role) {
                localStorage.setItem("ilera", JSON.stringify(user.user_info));
                if(user.user_info.role === "administrateur") {
                    window.location.href = "/dashboard/admin";
                } else if (user.user_info.role === "Utilisateur standard" || user.user_info.role === "patient" || user.user_info.role === "Patient") {
                    window.location.href = "/dashboard/patient";
                } else {
                    window.location.href = "/dashboard/doctor";
                }
            } else {
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
        }).catch((e) => {
            setIsLoading(false);
        });
    }

    return (
        <div className='login'>
            <div className='login_content'>
                <div className='login_content_head'>
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Se connecter
                        </div>
                        <div className='login_content_head_title_bottom'>
                            <span>Veuillez saisir vos identifiants pour vous connecter à ilera</span> 
                            {/* <img src={ilera} alt='ilera'/> */}
                        </div>
                    </div>
                </div>

                <form name="login" action='#' method='POST' className='login_content_form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        login();
                    }}
                >
                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Nom d'utilisateur</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="userName" required placeholder="votre nom d'utilisateur" type='text'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre nom d'utilisateur unique pour l'application</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Mot de passe</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="password" required placeholder='***********' type='password'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre mot de passe</span>
                        </div>
                    </div>

                    <div className='login_content_form_button'>
                        <button>
                            Se connecter
                        </button>

                        <a href='/registration'>
                            Créer un compte
                        </a>
                    </div>

                    <div className='login_content_form_button returnHome'>
                        <a href='/'>
                            Retourner à l'accueil
                        </a>
                    </div>
                </form>
            </div>

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);