import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class ChatRequest {
    create(data) {
        return (
            axios({
                method: 'post',
                data: data,
                withCredentials: true,
                url: PATH__.api_root + "chat/create_conversation/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetConversation(id) {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "chat/list_conversations/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetConversationMessages(id) {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "chat/get_messages/?conversation_id=" + id,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetUsersContacted() {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "chat/list_utilisateur_contacter/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    SendPrescription(data) {
        return (
            axios({
                method: 'post',
                withCredentials: true,
                url: PATH__.api_root + "chat/send_prescription/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    UpdatePrescription(id, data) {
        return (
            axios({
                method: 'post',
                withCredentials: true,
                url: PATH__.api_root + "chat/modifier_donnees/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    deletePrescription(id) {
        return (
            axios({
                method: 'post',
                withCredentials: true,
                url: PATH__.api_root + "chat/supprimer_prescriptions/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetUsersPrescriptionsList() {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "chat/liste_prescription/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetOnePrescription(id) {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "chat/receive_prescription/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    SendTextMessage(data) {
        return (
            axios({
                method: 'post',
                withCredentials: true,
                data: data,
                url: PATH__.api_root + "chat/send_message/",
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                },
            })
        )
    }
}

export default ChatRequest;