import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class DoctorRequest {
    get_rdv_list(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "api/api/liste_rdv_medecin/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get_rdv_confirm_list(id) {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "api/api/confirmations_liste/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    confirm_or_refuse_rdv(rdv_id, data) {
        return (
            axios({
                method: 'post',
                maxBodyLength: Infinity,
                withCredentials: true,
                url: PATH__.api_root + "api/api/confirmer_rdv_medecin/" + rdv_id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            })
        )
    }

    get_rdv_list_lu(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "api/api/liste_rdv_lus/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    create_zoom() {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "videoconference_app/meeting/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default DoctorRequest;