import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class AuthRequest {
    login(user) {
            return (
                axios({
                    method: 'post',
                    data: user,
                    withCredentials: true,
                    url: PATH__.api_root + "api/api/accounts/login/",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            )
        }
        // login(user) {
        //     return (
        //         axios({
        //             method: 'post',
        //             data: user,
        //             url: PATH__.api_root + "login.php",
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //         })
        //     )
        // }

    registration(user) {
        return (
            axios({
                method: 'post',
                data: user,
                url: PATH__.api_root + "api/api/signup/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "api/api/update_user/" + id + "/",
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addEvent(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "api/api/medecin/" + id + "/add_event/",
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    createConversation(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "chat/create_conversation/",
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    getAllUsers() {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "api/api/liste_utilisateurs/",
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    deleteUser(id, data) {
        return (
            axios({
                method: 'POST',
                url: PATH__.api_root + "api/api/supprimer_utilisateur/" + id + "/",
                withCredentials: true,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addUser(id, data) {
        return (
            axios({
                method: 'POST',
                url: PATH__.api_root + "api/api/ajouter_utilisateur/",
                withCredentials: true,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    updateUser(id, data) {
        return (
            axios({
                method: 'POST',
                url: PATH__.api_root + "api/api/modifier_utilisateur/" + id + "/",
                withCredentials: true,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default AuthRequest;