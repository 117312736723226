import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle';

/**Importation des images */
// import stn from '../assets/images/sante-bien-etre-png-175.png';
import servicesign11 from './assets/images/shapes/service-sign-1-1.png';
// import ilera_logo from './assets/images/ilèra_logo.png';
import teleconsultation from './assets/images/shapes/teleconsultation.png';
import discuss from './assets/images/shapes/discuss.png';
import partage from './assets/images/shapes/partage.png';
import testiqoute11 from './assets/images/shapes/testi-qoute-1-1.png';
import ilera_logo_small from '../assets/images/ilera.png';

// import './assets/css/owl.carousel.min.css';
import './assets/css/owl.theme.default.min.css';
import './assets/css/hover-min.css';

import './assets/js/scroll';

import Xanimation from '../helpers/x-animations';
const animer = new Xanimation();
animer.init( 300 );

function AboutIlera( props ) {
    useEffect(() => {
        window.onload = animer.start();
        return () => {
            animer.stop();
        }
    },[]);
    return (
        <>
            <section className="section2">
                <div className="section2_title">
                    <span className="title-style-1">
                        A propos de nous
                    </span>
                </div>

                <div className="section2_paragraph" style={{marginBottom: "-70px"}}>
                    <p style={{textAlign: "center", marginTop: "-30px"}}>
                        Nous sommes une plateforme d'e-santé innovante facilitant
                        <br/>
                        l'interaction entre les patients et les professionnels de la santé.
                    </p>
                </div>
            </section>

            <div className='homeViewContent'>
    <section className="service-two">
        <div className="error-404__bubble-1"></div>
        <div className="error-404__bubble-2"></div>
        <div className="error-404__bubble-3"></div>
        <div className="error-404__bubble-4"></div>
        <div className="error-404__bubble-5"></div>
        <div className="error-404__bubble-6"></div>
        <div className="error-404__bubble-7"></div>
        <div className="error-404__bubble-8"></div>
        <div className="container">
            <div className="row no-gutters">
                <div className="col-xl-6 d-flex about_lnN">
                    <div className="my-auto">
                        <div className="service-two__main x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className='section2_icon'>
                                <img src={ilera_logo_small} alt='ilera'/>
                            </div>
                            <div className="section2_subtitle">
                                <h3>
                                    Nous sommes <br/> Une plateforme de télémédecine innovante.
                                </h3>
                            </div>
                            <p className="text-justify">Notre plateforme de télémédecine vise à simplifier l'interaction entre patients et professionnels de la santé, assurant un accès universel aux soins, où que vous soyez.</p>
                            <img src={servicesign11} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="service-two__box-wrap">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="service-two__box  wow flipInY x-animation" data-wow-delay="0ms" data-wow-duration="1500ms"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <div className="service-two__box-icon">
                                        <div className="service-two__box-circle"></div>
                                        <img src={teleconsultation} alt=""/>
                                    </div>
                                    <h3><a href="service-d-seo.html">Téléconsultation Médicale</a></h3>
                                    <p>Ilera offre un service de téléconsultation, Une approche novatrice éliminant les barrières physiques, offrant un accès instantané aux conseils médicaux.</p>
                                </div>
                                <div className="service-two__box  wow flipInY x-animation" data-wow-delay="300ms" data-wow-duration="1500ms"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <div className="service-two__box-icon">
                                        <div className="service-two__box-circle"></div>
                                        <img src={discuss} alt=""/>
                                    </div>
                                    <h3><a href="service-d-seo.html">Connexion sécurisé</a></h3>
                                    <p>Notre plateforme crée un lien virtuel entre patients et professionnels de la santé, améliorant ainsi la coordination des soins médicaux.</p>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex x-animation"
                                x-animation="from-bottom"
                                x-animation-delay="0s"
                                x-animation-duration="1s"
                            >
                                <div className="my-auto">
                                    <div className="service-two__box  wow flipInY" data-wow-delay="600ms" data-wow-duration="1500ms">
                                        <div className="service-two__box-icon">
                                            <div className="service-two__box-circle"></div>
                                            <img src={partage} alt=""/>
                                        </div>
                                        <h3><a href="service-d-seo.html">Partage de connaissances</a></h3>
                                        <p>Nous nous distinguons par sa mission de favoriser le partage des connaissances médicales. En facilitant l'échange d'informations entre les professionnels de la santé.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='section2'>
        <div className='section2_icon x-animation'
            x-animation="from-bottom"
            x-animation-delay="0s"
            x-animation-duration="1s"
        >
            <img src={ilera_logo_small} alt='ilera'/>
        </div>

        <div className='section2_paragraph x-animation'
            x-animation="from-bottom"
            x-animation-delay="0s"
            x-animation-duration="1s"
        >
            <p>
                Ilera propose une solution moderne et accessible, facilitant l'interaction entre les patients et les professionnels de la santé. En mettant l'accent sur la téléconsultation médicale, notre plateforme permet aux utilisateurs de consulter des
                professionnels de la santé qualifiés à distance, éliminant ainsi la nécessité de déplacements physiques. Grâce à une connexion directe entre les patients et les professionnels, la plateforme favorise le partage d'informations et améliore
                la compréhension des besoins individuels en matière de santé. Notre engagement est de rendre les soins de santé plus accessibles, offrant une expérience de télémédecine pratique et efficace.
            </p>
        </div>
    </section>
    
    <section className="section2 section2_form">
        <div className="section2_title x-animation"
            x-animation="from-bottom"
            x-animation-delay="0s"
            x-animation-duration="1s"
        >
            <span className="title-style-1">
                Notre Politique
            </span>
        </div>
        <div className="section2_subtitle x-animation"
            x-animation="from-bottom"
            x-animation-delay="0s"
            x-animation-duration="1s"
        >
            <h3>
                Nous prenons soin de nos souscripteurs.
            </h3>
        </div>
    </section>
    <section className="testimonials-one testimonials-one__about-one x-animation"
        x-animation="from-bottom"
        x-animation-delay="0s"
        x-animation-duration="1s"
    >
        <div className="container">
            <div className="testimonials-one__carousel thm__owl-carousel owl-carousel owl-theme" data-options='{
                "items": 3, "margin": 40, "smartSpeed": 700, "autoplay": true, "autoplayTimeout": 5000,
                "autoplayHoverPause": true, "nav": false, "dots": false, "loop": true, "responsive": {
                    "0": { "items": 1, "margin": 0},
                    "767": { "items": 1, "margin": 0},
                    "991": { "items": 2, "margin": 40},
                    "1199": { "items": 2, "margin": 40},
                    "1200": { "items": 3, "margin": 40}
                }
            }'>
                <div className="item">
                    <div className="testimonials-one__single">
                        <div className="testimonials-one__icon">
                            <img src={testiqoute11} alt=""/>
                        </div>
                        <p>Ilera offre des soins médicaux à distance, éliminant les obstacles géographiques pour des consultations depuis n'importe où.</p>
                        <h3>ilera</h3>
                    </div>
                </div>
                <div className="item">
                    <div className="testimonials-one__single">
                        <div className="testimonials-one__icon">
                            <img src={testiqoute11} alt=""/>
                        </div>
                        <p>Ilera offre un accès facile à l'historique médical, permettant une coordination efficace des soins, des rappels personnalisés et une collaboration pour le bien-être.</p>
                        <h3>ilera</h3>
                    </div>
                </div>
                <div className="item">
                    <div className="testimonials-one__single">
                        <div className="testimonials-one__icon">
                            <img src={testiqoute11} alt=""/>
                        </div>
                        <p>Ilera offre l'interactions directes entre patients et professionnels de la santé, offrant des conseils spécifiques et une compréhension approfondie.</p>
                        <h3>ilera</h3>
                    </div>
                </div>
                <div className="item">
                    <div className="testimonials-one__single">
                        <div className="testimonials-one__icon">
                            <img src={testiqoute11} alt=""/>
                        </div>
                        <p> Notre plateforme connecte les patients à un réseau de professionnels de la santé qualifiés, assurant une prise en charge efficace et personnalisée.</p>
                        <h3>ilera</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutIlera);