class Verifier {
    _password(password, minLength) {
        let verificationResult = {
            number: false,
            string: false,
            special_character: false,
            minimum_character: false,
        };

        let expression1 = new RegExp("[0-9]");
        let expression2 = new RegExp("[a-z, A-Z]");
        let expression3 = new RegExp("[@,#,%,&,_,$]");
        // let expression3 = new RegExp("^[0-9]{2}/[0-9]{2}$");
        // let expression4 = new RegExp("^[0-9]{2}/[0-9]{3,}$");

        if (expression1.test(password)) {
            verificationResult.number = true;
        }

        if (expression2.test(password)) {
            verificationResult.string = true;
        }

        if (expression3.test(password)) {
            verificationResult.special_character = true;
        }

        if (password.length >= minLength) {
            verificationResult.minimum_character = true;
        }

        return verificationResult;
    }
}

export default Verifier;