import React from 'react';
import { connect } from 'react-redux';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';

import '../styles/dashboard.agenda.style.scss';

// function renderEventContent(eventInfo) {
//     console.log(eventInfo)
//     return (
//       <>
//         <b>{eventInfo.timeText}</b>
//         <p className='eventTitle'>{eventInfo.event.title}</p>
//       </>
//     )
// }

function DashboardAdminAGenda( props ) {
    
    return (
        <div className='agenda'>
            {/* <div>
                Agenda
            </div> */}

            <div className='agenda_content'>
                {/* <div className='agenda_content_addPlan'>
                    <div className='agenda_content_addPlan_drag'>

                    </div>
                    <div className='agenda_content_addPlan_button'>
                        <button>
                            Ajouter un événement
                        </button>
                    </div>
                </div>

                <div className='agenda_content_viewPlan'>
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        eventContent={renderEventContent}
                        events={[
                            { title: 'Consultation Mr Jean', date: '2023-11-18' },
                            { title: 'Opération', date: '2023-11-21' }
                        ]}
                        dayHeaderFormat={{ weekday: 'short'}}
                    />
                </div> */}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdminAGenda);