import React from 'react';
// import { Link } from 'react-router-dom';

import './style/style.scss';
import './style/responsive.style.scss';


function Card(props){
    return (
        <div className="Card">
            <div className="Card_imageContainer">
                <a href={props.link}
                    onClick={() => {
                        props.action && props.action();
                    }}
                >
                    <img className="Card_image" alt={""} src={props.elt.logo} />
                </a>
                {
                    props.elt.odd ?
                        <div className="Card_odd">
                            {
                                props.elt.odd.slice(0).reverse().map((odd, index) => (
                                    <div key={"ODD" + index} className="odd">
                                        <a href={"/#"}
                                            onClick={()=>{
                                                return true;
                                            }}
                                        >
                                            <img className="odd_" alt="odd" src={"./upload/logos/ODD/odd" +odd+ ".png"}/>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    :
                        null
                }
            </div>
            <div className="Card_body">
                <a href={props.link}
                    onClick={() => {
                        // props.action && props.action();
                    }}
                >
                    <div className="Card_body_title">
                        <span>{props.elt.name && props.elt.name}</span>
                    </div>
                </a>
                {/* <div className="Card_body_title Card_body_date">
                    <span>{props.elt.month}/{props.elt.year}</span>
                </div> */}
                <div className="card_separator_line"></div>
                <div className="Card_body_text text-form lineHeight1">
                    <p>{props.elt.description}</p>
                </div>
                <div className="Card_competence">
                    {
                        props.elt.competences &&
                        props.elt.competences.map((competence, index) => (
                            <div key={"competences" + index} className="competence">
                                <a className="link" href={props.competenceLink}
                                    onClick={() => {
                                        // props.action2 && props.action2();
                                    }}
                                >
                                    <span>
                                        {competence.name}
                                    </span>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='Card_foot'>
                <a className="link" href={props.link}>
                    <span>Rejoignez-nous</span>
                    <span className='Card_foot_fake'></span>
                </a>
            </div>
        </div>
    )
}

export default Card;