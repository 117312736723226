import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class CalendarRequest {
    add(id, event) {
        return (
            axios({
                method: 'post',
                data: event,
                url: PATH__.api_root + "api/api/medecin/" + id + "/add_event/",
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "api/api/medecin/" + id + "/view_user_events/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default CalendarRequest;