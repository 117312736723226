import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle';

/**Importation des images */
// import stn from '../assets/images/sante-bien-etre-png-175.png';

import '../styles/homeView.screen.style.scss';

function FaqIlera( props ) {
    const contenuHTML = `

    <section class="faq-one">
        <div class="container">
            <div class="row high-gutters">
                <div class="col-lg-6">
                    <div class="accrodion-grp" data-grp-name="career-one__accrodion">
                        <div class="accrodion ">
                            <div class="accrodion-title">
                                <h4>Qu'est-ce que la plateforme Ilera et comment fonctionne-t-elle?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Ilera est une plateforme de télémédecine qui facilite l'interaction entre les patients et les professionnels de la santé. Elle propose des téléconsultations médicales, connecte les patients aux professionnels de
                                        la santé et favorise le partage des connaissances pour améliorer l'accessibilité aux soins de santé.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accrodion">
                            <div class="accrodion-title">
                                <h4> Comment puis-je m'inscrire sur la plateforme Ilera?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Pour vous inscrire, cliquez sur le bouton d'inscription sur la page d'accueil, remplissez le formulaire requis, et suivez les étapes pour créer votre compte. Une fois inscrit, vous pourrez accéder aux services de
                                        télémédecine offerts par la plateforme.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accrodion">
                            <div class="accrodion-title">
                                <h4>De quels périphériques doit-on être muni(e) pour accéder aux services ?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Un smartphone ou une tablette ou un ordinateur, la plateforme est disponnible sur tous ces périphériques.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accrodion">
                            <div class="accrodion-title">
                                <h4>Ilera prend t-il en charge les soins ? </h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Vous pourrez accéder à <a href="https://www.tojumi.com/">tojumi</a> pour la prise en charge de vos soins.</p>
                                </div>
                                <!-- /.inner -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.col-lg-6 -->
                <div class="col-lg-6">
                    <div class="accrodion-grp" data-grp-name="career-one__accrodion-2">
                        <div class="accrodion ">
                            <div class="accrodion-title">
                                <h4>Les échanges sont-ils sécurisés sur ILERA ?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Oui, nous utilisons des API avec des correctifs de sécurité accentués afin de garantir la confidentialité des échanges.</p>
                                </div>
                                <!-- /.inner -->
                            </div>
                        </div>
                        <div class="accrodion ">
                            <div class="accrodion-title">
                                <h4>Comment puis-je accéder à mes informations médicales enregistrées sur la plateforme?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Une fois connecté, vous pouvez accéder à votre tableau de bord personnel pour consulter et gérer vos informations médicales. La plateforme garantit la confidentialité et la sécurité des données des utilisateurs.</p>
                                </div>
                                <!-- /.inner -->
                            </div>
                        </div>
                        <div class="accrodion">
                            <div class="accrodion-title">
                                <h4>Quels types de professionnels de la santé sont disponibles sur Ilera?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Ilera propose une variété de professionnels de la santé, y compris des médecins généralistes, des spécialistes et d'autres professionnels de la santé qualifiés pour répondre à divers besoins médicaux.</p>
                                </div>
                                <!-- /.inner -->
                            </div>
                        </div>
                        <div class="accrodion">
                            <div class="accrodion-title">
                                <h4>Comment puis-je obtenir de l'aide en cas de problème technique ou de question sur la plateforme?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <p>Pour toute assistance technique ou question, vous pouvez contacter notre équipe de support via le formulaire de contact sur le site ou en envoyant un e-mail à <a href="#">contact@ilera.tech</a></p>
                                </div>
                                <!-- /.inner -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.col-lg-6 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </section>
    `
    return (
        <>
            <section className="section2">
                <div className="section2_title">
                    <span className="title-style-1">
                        FAQ
                    </span>
                </div>

                <div className="section2_subtitle">
                    <h3>
                        Questions Fréquements Posées
                    </h3>
                </div>
            </section>

            <div className='homeViewContent' dangerouslySetInnerHTML={{__html: contenuHTML}}>
            
            </div>

            <section className="section2 section2_form_">
                <div className="section2_title">
                    <span className="title-style-1">
                        Question
                    </span>
                </div>
                <div className="section2_subtitle">
                    <h3>
                        Avez-vous d'autres questions? <br/>Contactez nous!
                    </h3>
                </div>

                <div className='section2_form'>
                    <form action="assets/inc/sendemail.php" className="contact-one__form">
                        <div className="row">
                            <div className="col-md-6">
                                <input type="text" placeholder="Votre nome"/>
                            </div>
                            <div className="col-md-6">
                                <input type="text" placeholder="Votre Email"/>
                            </div>
                            <div className="col-md-6">
                                <input type="text" placeholder="Numéro de téléphone"/>
                            </div>
                            <div className="col-md-6">
                                <input type="text" placeholder="Sujet "/>
                            </div>
                            <div className="col-md-12">
                                <textarea name="message" placeholder="Massage"></textarea>
                                
                            </div>
                            <div className="col-md-12 text-left">
                                <button type="submit" className="thm-btn contact-one__btn">Envoyer</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqIlera);