import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';


import '../styles/dashboard.request.style.scss';

import loader from '../assets/gif/loader.gif';

import UserRequest from '../helpers/user.requests.helper';
import PATH_ from '../GLOBAL-VAR/globals-urls';


const USR = new UserRequest();
const PATH__ = new PATH_();

function DashboardPatientRequest( props ) {
    const [viewTarif, setViewTarif] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const cost = [
        { "speciality": "Médecin Généraliste", "cost": [15000] },
        { "speciality": "Pédiatre", "cost": [34500] },
        { "speciality": "Cardiologue", "cost": [34404] },
        { "speciality": "Dentiste", "cost": [20694] },
        { "speciality": "Dermatologue", "cost": [20694] },
        { "speciality": "Gynécologue", "cost": [20694] },
        { "speciality": "Neurologue", "cost": [33896] },
        { "speciality": "Neuropsychiatre", "cost": [33896] },
        { "speciality": "Ophtalmologue", "cost": [20694] },
        { "speciality": "Oto-rhino-laryngologiste (ORL)", "cost": [20694] },
        { "speciality": "Orthopédiste", "cost": [20694] },
        { "speciality": "Pneumologue", "cost": [20694] },
        { "speciality": "Psychiatre", "cost": [33896] },
        { "speciality": "Rhumatologue", "cost": [20694] },
        { "speciality": "Urologue", "cost": [20694] }
    ];
      
    const [RDVDate, setRDVDate] = useState("");
    const [RDVTime, setRDVTime] = useState("");
    const [user, setUser] = useState({
        ID: "",
        nom : "",
        prenom : "",
        email : "",
        date_naissance: "",
        numero_telephone: "",
    });

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("ilera"));
        if(currentUser!==null) {
            setUser(currentUser);
        }
        
    },[]);

    function MakeRequest(data) {
        setIsLoading(true);
        USR.makeRequest(data).then((response) => {
            setIsLoading(false);
            let res = response.data;
            localStorage.setItem("ilera-patient-request", JSON.stringify(res));
            if(res.success === true) {
                alert("Votre demande a bien été prise en compte. Veuillez maintenant choisir un tarif et procéder au paiement.");
            } else {
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
            setViewTarif(true);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }
        
    return (
        <div className='request'>
            <div className="request_content">
                <form action='#' method='POST' className='form' name='request'
                    onSubmit={(e) => {
                        e.preventDefault();
                        let date_naissance = new Date(document.forms["request"]["borne_month"].value + "-" + document.forms["request"]["borne_day"].value);
                        let year = date_naissance.getFullYear();
                        let month = (date_naissance.getMonth() + 1).toString().padStart(2, '0'); // Mois indexé à partir de zéro
                        let day = date_naissance.getDate().toString().padStart(2, '0');

                        let date_rdv = new Date(RDVDate);
                        let year_ = date_rdv.getFullYear();
                        let month_ = (date_rdv.getMonth() + 1).toString().padStart(2, '0'); // Mois indexé à partir de zéro
                        let day_ = date_rdv.getDate().toString().padStart(2, '0');
                        
                        let data = {
                            "utilisateur_id": user.ID,
                            "nom": user.nom,
                            "prenom": user.prenom,
                            "date_naissance": `${year}-${month}-${day}`,
                            "email": user.email,
                            "numero_telephone": user.numero_telephone,
                            "genre": document.forms["request"]["sex"].value,
                            "adresse": document.forms["request"]["address"].value,
                            "date_rdv": `${year_}-${month_}-${day_}`,
                            "heure_debut": RDVTime,
                            "specialite_medecin": document.forms["request"]["specialite_medecin"].value,
                            "motif_consultation": document.forms["request"]["motif_consultation"].value
                        }
                        MakeRequest(data);
                    }}
                >
                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Nom</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.nom} name='lastName' required placeholder='nom' type='text'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            nom: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre nom</span>
                            </div>
                        </div>

                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Prénom</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.firstName} name='firstName' required placeholder='Prénom' type='text'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            prenom: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre prénom</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Date de naissance</span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='borne_month' required placeholder='dd/mm/yy' type='month'/>
                            </div>
                            <div className='form_input_subText'>
                                {/* <span>Âge: {new Date().getFullYear()-patientGeneralInformation.borneYear}<strong></strong></span> */}
                            </div>
                        </div>
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Jour de naissance</span>
                            </div>
                            <div className='form_input_balise'>
                                <select name="borne_day">
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                    <option>31</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Sexe à l'état civil</span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='sex' value="Féminin" id='female' type='radio'/>
                                <label htmlFor="female">Féminin</label>
                            </div>
                            <div className='form_input_subText'>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span></span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='sex' value="Masculin" id='mal' type='radio'/>
                                <label htmlFor="mal">Masculin</label>
                            </div>
                            <div className='form_input_subText'>
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Numéro de téléphone</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.numero_telephone} name='phoneNumber' required placeholder='Prénom' type='tel'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            numero_telephone: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre numéro</span>
                            </div>
                        </div>

                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Email</span>
                            </div>
                            <div className='form_input_balise'>
                                <input value={user.email} name='email' required placeholder='' type='email'
                                    onChange={(e)=>{
                                        setUser({
                                            ...user,
                                            email: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className='form_input_subText'>
                                <span>Votre email</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex'>
                        <div className='form_input'>
                            <div className='form_input_title'>
                                <span>Adresse</span>
                            </div>
                            <div className='form_input_balise'>
                                <input name='address' placeholder='' type='text'/>
                            </div>
                            <div className='form_input_subText'>
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <div className='form'>
                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Spécialité ou type de médecin recherché</span>
                                </div>
                                <div className='form_input_balise'>
                                    <select name="specialite_medecin">
                                        {
                                            cost.map((sp, index) => (
                                                <option value={sp.speciality}>
                                                    {sp.speciality}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Motif de la consultation</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <textarea name="motif_consultation" required/>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>
                            </div>

                        <div className="flex">
                            <div className='form_input'>
                                <div className='form_input_title'>
                                    <span>Préférences de date et d'heure</span>
                                </div>
                                <div className='form_input_balise'>
                                    <div className='flex datePickerContent'>
                                        <div className='datePickerContent_'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateCalendar']}>
                                                    <DateCalendar
                                                        referenceDate={dayjs(new Date())}
                                                        views={['year', 'month', 'day']}
                                                        onChange={(e)=>{
                                                            setRDVDate(new Date(e.$d).toISOString());
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                        <div className='datePickerContent_'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={[
                                                        'StaticTimePicker',
                                                    ]}
                                                >
                                                    
                                                    <DemoItem>
                                                        <StaticTimePicker defaultValue={dayjs(new Date())} 
                                                            onChange={(e)=>{
                                                                // console.log(e);
                                                                setRDVTime(e.$H + ":" + e.$m + ":" + e.$s);
                                                            }}
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className='form_input_subText'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className='login_content_form_button'>
                            <button>
                                Soumettre
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            {
                viewTarif ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setViewTarif(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Sellectionnez le tarif qui vous convient le mieux
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <section className='table_content'>
                                    <table class="table table--bordered" align="center">
                                        <caption class="table__title">Tarifs de base d'une consultation pour les médecins spécialistes</caption>
                                        <thead class="table--secondary">
                                            <tr>
                                                <th>Médecins spécialistes</th>
                                                <th>Tarifs de consultation du médecin</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cost.map((ct, index) => (
                                                    <tr class="cell--center">
                                                        <th>{ct.speciality}</th>
                                                        <td>✔️ {ct.cost}xof</td>
                                                        <td>
                                                            <button
                                                                className='tab-button-1'
                                                                onClick={(e) => {
                                                                    window.location.href =  PATH__.api_root + 'paiement/paiement_facture/'+user.ID+'/'+ct.cost+'/'+ct.speciality+"/";
                                                                    // window.location.href =  '/payment?coast='+ct.cost+'&speciality='+ct.speciality;
                                                                }}
                                                            >
                                                                Choisir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </section>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                isLoading ?
                    <div>
                        <div className='globalALert_back'></div>
                        <div className='globalALert'>
                            <div className='globalALert_content'>
                                <div className='globalALert_content_loader'>
                                    <img src={loader} alt='freeJobs'/>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
            }
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPatientRequest);