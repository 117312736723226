class Xanimation {
    constructor() {
        this.offset = 100;
        this.elementsList = [];
    }

    init(offset) {
        this.offset = offset;
    }

    /**-----------------------------Bottom--------------------------*/
    _translateFromBottom(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 1;
        e.style.transform = "translate3d(0, 0, 0)";
    }

    _translateToBottom(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 0;
        e.style.transform = "translate3d(0, 100px, 0)";
    }

    /**----------------------Scale 0------------------------*/

    _translateFromScale0(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 1;
        e.style.transform = "scale(1)";
    }

    _translateToScale0(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 0;
        e.style.transform = "scale(0)";
    }

    /**---------------Left--------------------------------------*/
    _translateFromLeft(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 1;
        e.style.transform = "translate3d(0, 0, 0)";
    }

    _translateToLeft(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 0;
        e.style.transform = "translate3d(-100%, 0, 0)";
    }



    /**-------------Right---------------------------------------*/
    _translateFromRight(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 1;
        e.style.transform = "translate3d(0, 0, 0)";
    }

    _translateToRight(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 0;
        e.style.transform = "translate3d(100%, 0, 0)";
    }


    /**-------------width---------------------------------------*/
    _translateFromWidth0(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 1;
        e.style.transform = "100%";
    }

    _translateToWidth0(e, duration) {
        e.style.transition = "all " + duration;
        e.style.opacity = 0;
        e.style.width = "0%";
    }

    start = () => {
        this.elementsList = document.getElementsByClassName("x-animation");
        for (let i = 0; i < this.elementsList.length; i++) {
            if (this.elementsList[i].hasAttribute("x-animation")) {
                if (this.elementsList[i].getAttribute("x-animation") === "from-bottom") {
                    this._translateToBottom(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                } else if (this.elementsList[i].getAttribute("x-animation") === "from-left") {
                    this._translateToLeft(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                } else if (this.elementsList[i].getAttribute("x-animation") === "from-right") {
                    this._translateToRight(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                } else if (this.elementsList[i].getAttribute("x-animation") === "from-Width0") {
                    this._translateToWidth0(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                }
            }
        }
        window.onload = document.addEventListener('scroll', this._onScrollPage);
    }

    _onScrollPage = () => {
        for (let i = 0; i < this.elementsList.length; i++) {
            if (window.pageYOffset + window.innerHeight - this.offset >= this.elementsList[i].offsetTop) {
                if (this.elementsList[i].hasAttribute("x-animation")) {
                    if (this.elementsList[i].getAttribute("x-animation") === "from-bottom") {
                        this._translateFromBottom(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                    } else if (this.elementsList[i].getAttribute("x-animation") === "from-left") {
                        this._translateFromLeft(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                    } else if (this.elementsList[i].getAttribute("x-animation") === "from-right") {
                        this._translateFromRight(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                    } else if (this.elementsList[i].getAttribute("x-animation") === "from-Width0") {
                        this._translateFromWidth0(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                    }
                }
            } else if (window.pageYOffset + window.innerHeight - this.offset < this.elementsList[i].offsetTop) {
                if (this.elementsList[i].getAttribute("x-animation") === "from-bottom") {
                    this._translateToBottom(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                } else if (this.elementsList[i].getAttribute("x-animation") === "from-left") {
                    this._translateToLeft(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                } else if (this.elementsList[i].getAttribute("x-animation") === "from-right") {
                    this._translateToRight(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                } else if (this.elementsList[i].getAttribute("x-animation") === "from-Width0") {
                    this._translateToWidth0(this.elementsList[i], this.elementsList[i].getAttribute("x-animation-duration"));
                }
            }
        }
    }

    stop = () => {
        document.removeEventListener('scroll', this._onScrollPage);
    }
}

export default Xanimation;