import React from 'react';
import { connect } from 'react-redux';

import '../styles/tarifsView.screen.style.scss';

/**Importation des images */



function TarifView( props ) {
    return (
        <div className='TarifViewContent'>
            <section className="section2">
                <div className="section2_title">
                    <span className="title-style-1">
                        Tarifs
                    </span>
                </div>

                <div className='section2_paragraph'>
                    <p>
                        Plusieurs tarifs de consultations ont été instaurés pour répondre aux différents besoins des patients et prendre en compte les pratiques des médecins.
                        <br/>
                        <br/>
                        Ainsi, des tarifs sont mis en place pour mieux prendre en charge des consultations
                    </p>
                </div>
            </section>
            {/* <section className="page-header">
                <div className="particles-snow" id="header-snow"></div>
                <div className="container text-center">
                    <h3 className="a-title">Tarifs</h3>
                    <ul className="list-unstyled thm-breadcrumb">
                        <li>
                            <span>
                                <h5>
                                    Plusieurs tarifs de consultations ont été instaurés pour répondre aux différents besoins des patients et prendre en compte les pratiques des médecins.
                                    <br/>
                                    <br/>
                                    Ainsi, des tarifs sont mis en place pour mieux prendre en charge des consultations
                                </h5>
                            </span>
                        </li>
                    </ul> 
                </div>
            </section> */}

            <section className='pricingList'>
                <section className="service-three">
                    <div className="section__bubble-1"></div>
                    {/* <!-- /.section__bubble-1 --> */}
                    <div className="section__bubble-2"></div>
                    {/* <!-- /.section__bubble-2 --> */}
                    <div className="section__bubble-3"></div>
                    {/* <!-- /.section__bubble-3 --> */}
                    <div className="section__bubble-4"></div>
                    {/* <!-- /.section__bubble-4 --> */}
                    <div className="section__bubble-5"></div>
                    {/* <!-- /.section__bubble-5 --> */}
                    <div className="section__bubble-6"></div>
                    {/* <!-- /.section__bubble-6 --> */}
                    <div className="section__bubble-7"></div>
                    {/* <!-- /.section__bubble-7 --> */}
                    <div className="section__bubble-8"></div>
                    {/* <!-- /.section__bubble-8 --> */}
                    <div className="container">
                        <div className='pricingList_content'>
                            <div className='pricingList_content_'>
                                <div className='pricingList_content_elt'>
                                    <h3>
                                        Cardiologue
                                    </h3>
                                    <span>
                                        5200 XOF
                                    </span>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </div>

                                <div className='pricingList_content_elt'>
                                    <h3>
                                        Dentiste
                                    </h3>
                                    <span>
                                        3150 XOF
                                    </span>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </div>

                                <div className='pricingList_content_elt'>
                                    <h3>
                                        Dermatologue
                                    </h3>
                                    <span>
                                        3150 XOF
                                    </span>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </div>

                                <div className='pricingList_content_elt'>
                                    <h3>
                                        Gynécologue
                                    </h3>
                                    <span>
                                        9650 XOF
                                    </span>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </div>

                                <div className='pricingList_content_elt'>
                                    <h3>
                                        Neurologue
                                    </h3>
                                    <span>
                                        10250 XOF
                                    </span>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='table_content'>
                    <table class="table table--bordered" align="center">
                        <caption class="table__title">Tarifs de base d'une consultation pour les médecins spécialistes</caption>
                        <thead class="table--secondary">
                            <tr>
                                <th>Médecins spécialistes</th>
                                <th>Tarifs de consultation du médecin</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="cell--center">
                                <th>Cardiologue</th>
                                <td>✔️ 5250 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Dentiste</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Dermatologue</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Gynécologue</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Neurologue</th>
                                <td>✔️ 5170 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Neuropsychiatre</th>
                                <td>✔️ 5170 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Ophtalmologue</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>ORL</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Orthopédiste</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Pneumologue</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Psychiatre</th>
                                <td>✔️ 5170 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Rhumatologue</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                            <tr class="cell--center">
                                <th>Urologue</th>
                                <td>✔️ 3150 XOF</td>
                                <td>
                                    <a href="##">
                                        Choisir
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </section>
        </div>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(TarifView);