import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class UserRequest {
    add(user) {
        return (
            axios({
                method: 'post',
                data: user,
                url: PATH__.api_root + "api/api/signup/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    getAll(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "get_all_doctor.php/?id=" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    makeRequest(data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "api/api/demande_rendez_vous/",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            })
        )
    }

    startMatching(data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "api/api/matching_medecin/",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            })
        )
    }

    selectMatchingAndSendToDoctor(data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "api/api/envoyer_rdv_medecins_apres_paiement/",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            })
        )
    }

    getMatchingResult(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "paiement/paiement_facture/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get_rdv_list(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "api/api/liste_rdv_utilisateur/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get_rdv_confirm_list(id) {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "api/api/confirmations_liste/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get_specialite_list() {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "api/api/lister_specialites/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get_doctor_list() {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "api/api/liste_noms_prenoms_medecins/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    get_all_doctor_list() {
        return (
            axios({
                method: 'get',
                withCredentials: true,
                url: PATH__.api_root + "api/api/medecins_par_specialite/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default UserRequest;