import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import '../styles/dashboard.appointment.style.scss';
import loader from '../assets/gif/loader.gif';

import AuthRequest from '../helpers/auth.requests.helper';
import DoctorRequest from '../helpers/doctor.requests.helper';
// import ChatRequest from '../helpers/chat.requests.helper';

const doctor_ = new DoctorRequest();
// const chat_ = new ChatRequest();

const currentUser = JSON.parse(localStorage.getItem("ilera"));
const Auth = new AuthRequest();

if(currentUser!==null && !currentUser.hasOwnProperty("confirmList")) {
    currentUser['confirmList'] = [];
}

if(currentUser!==null && !currentUser.hasOwnProperty("refuseList")) {
    currentUser['refuseList'] = [];
}

function DashboardDoctorAppointment( props ) {
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentViewList, setAppointmentViewList] = useState([]);
    const [appointmentNotViewList, setAppointmentNotViewList] = useState([]);
    const [password, setPassword] = useState(null);
    const [updateType, setUpdateType] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);
    // const newAppointmentList = [];

    // const validAppointmentList = []

    // const cancelAppointmentList = []
    function getRDVList() {
        // console.log(currentUser);
        setIsLoading(true);
        doctor_.get_rdv_list(currentUser.ID).then((response) => {
            console.log(response.data);
            setIsLoading(false);
            let l1 = response.data.rendezvous_lus.filter(function(objetA) {
                // Vérifier si l'objetA a un ID correspondant dans B
                return !currentUser.confirmList.some(function(objetB) {
                    return objetB.id === objetA.id;
                });
            });
            l1 = l1.filter(function(objetA) {
                // Vérifier si l'objetA a un ID correspondant dans B
                return !currentUser.refuseList.some(function(objetB) {
                    return objetB.id === objetA.id;
                });
            });
            setAppointmentViewList(l1);
            let l2 = response.data.rendezvous_non_lus.filter(function(objetA) {
                // Vérifier si l'objetA a un ID correspondant dans B
                return !currentUser.confirmList.some(function(objetB) {
                    return objetB.id === objetA.id;
                });
            });
            l2 = l2.filter(function(objetA) {
                // Vérifier si l'objetA a un ID correspondant dans B
                return !currentUser.refuseList.some(function(objetB) {
                    return objetB.id === objetA.id;
                });
            });
            setAppointmentNotViewList(l2);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    function confirmRDV(rdv) {
        setIsLoading(true);
        let data = JSON.stringify({
            action: "accepter",
        });
        let user_login = {
            username : currentUser.username,
            password : password,
        };
        Auth.login(user_login).then((response) => {
            console.log(rdv);
            if(response.data.user_info && response.data.user_info.role) {
                doctor_.confirm_or_refuse_rdv(rdv.id, data).then((response) => {
                    // let crv = {
                    //     participants: [currentUser.ID, rdv.utilisateur_demandeur_id]
                    // };
                    // console.log(crv);
                    currentUser.confirmList.push(rdv);
                    localStorage.setItem("ilera", JSON.stringify(currentUser));
                    setIsLoading(false);
                    setUpdateType(null);
                    alert(response.data.message);
                    window.location.reload();
                    // chat_.create(crv).then((response) => {
                        
                    // }).catch((e)=>{
                    //     console.log(e.response);
                    //     setUpdateType(null);
                    //     setIsLoading(false);
                    // });
                }).catch((e) => {
                    console.log(e.response);
                    setUpdateType(null);
                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
        }).catch((e) => {
            setIsLoading(false);
        });
    }

    function refuseRDV(rdv) {
        setIsLoading(true);
        let data = {
            action: "refuser",
        };
        let user_login = {
            username : currentUser.username,
            password : password,
        };
        Auth.login(user_login).then((response) => {
            if(response.data.user_info && response.data.user_info.role) {
                doctor_.confirm_or_refuse_rdv(rdv.id, data).then((response) => {
                    currentUser.refuseList.push(rdv);
                    localStorage.setItem("ilera", JSON.stringify(currentUser));
                    setIsLoading(false);
                    setUpdateType(null);
                    alert(response.data.message);
                    window.location.reload();
                }).catch((e) => {
                    console.log(e.response);
                    setIsLoading(false);
                    setUpdateType(null);
                });
            }else {
                setIsLoading(false);
                if(response.data.error) {
                    alert(response.data.error);
                }
            }
        }).catch((e) => {
            setIsLoading(false);
        });
    }

    function getRDVConfirmList() {
        // console.log(currentUser);
        setIsLoading(true);
        doctor_.get_rdv_confirm_list(currentUser.ID).then((response) => {
            setIsLoading(false);
            // setAppointmentViewList(response.data.rendezvous_lus);
            console.log(response);
        }).catch((e) => {
            console.log(e.response);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getRDVConfirmList();
        getRDVList();
    },[]);
        
    return (
        <>
        <div className='appointment'>
            <div className="prescription_list">
                <div className='prescription_list_head'>
                    <div className='prescription_list_head_left'>
                        <div className='prescription_list_head_left_content'>
                            <span>Filtrer par </span>
                            <select>
                                <option>
                                    Date
                                </option>
                                <option>
                                    Nom
                                </option>
                                <option>
                                    Genre
                                </option>
                                <option>
                                    ID
                                </option>
                            </select> 
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className='prescription_list_head_left'>
                        <div className='prescription_list_head_left_content'>
                            <select>
                                <option>
                                    20
                                </option>
                                <option>
                                    50
                                </option>
                                <option>
                                    80
                                </option>
                                <option>
                                    120
                                </option>
                                <option>
                                    200
                                </option>
                                <option>
                                    500
                                </option>
                                <option>
                                    Tout
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className='prescription_list_head_right'>
                        <div className='prescription_list_head_right_content'>
                            <input type='search' placeholder='Rechercher...' />
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="prescription_list_title">
                    <div className="prescription_list_title_export">
                        <button>
                            <span>PDF</span>

                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                                <g fill-rule="evenodd">
                                    <path d="M1251.654 0c44.499 0 88.207 18.07 119.718 49.581l329.223 329.224c31.963 31.962 49.581 74.54 49.581 119.717V1920H169V0Zm-66.183 112.941H281.94V1807.06h1355.294V564.706H1185.47V112.94Zm112.94 23.379v315.445h315.445L1298.412 136.32Z"/>
                                    <path d="M900.497 677.67c26.767 0 50.372 12.65 67.991 37.835 41.901 59.068 38.965 121.976 23.492 206.682-5.308 29.14.113 58.617 16.263 83.125 22.814 34.786 55.68 82.673 87.981 123.219 23.718 29.93 60.198 45.854 97.13 40.885 23.718-3.276 52.292-5.986 81.656-5.986 131.012 0 121.186 46.757 133.045 89.675 6.55 25.976 3.275 48.678-10.165 65.506-16.715 22.701-51.162 34.447-101.534 34.447-55.793 0-74.202-9.487-122.767-24.96-27.445-8.81-55.906-10.617-83.69-3.275-55.453 14.456-146.936 36.48-223.284 46.983-40.772 5.647-77.816 26.654-102.438 60.875-55.454 76.8-106.842 148.518-188.273 148.518-21.007 0-40.32-7.567-56.244-22.701-23.492-23.492-33.544-49.581-28.574-79.85 13.778-92.95 128.075-144.79 196.066-182.625 16.037-8.923 28.687-22.589 36.592-39.53l107.86-233.223c7.68-16.377 10.051-34.56 7.228-52.518-12.537-79.059-31.06-211.99 18.748-272.075 10.955-13.44 26.09-21.007 42.917-21.007Zm20.556 339.953c-43.257 126.607-119.718 264.282-129.996 280.32 92.273-43.37 275.916-65.28 275.916-65.28-92.386-88.998-145.92-215.04-145.92-215.04Z"/>
                                </g>
                            </svg>
                        </button>
                        <button>
                            <span>Excel</span>

                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM19 12C19.5523 12 20 12.4477 20 13V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H19C18.4477 21 18 20.5523 18 20V13C18 12.4477 18.4477 12 19 12ZM11.8137 12.4188C11.4927 11.9693 10.8682 11.8653 10.4188 12.1863C9.96935 12.5073 9.86526 13.1318 10.1863 13.5812L12.2711 16.5L10.1863 19.4188C9.86526 19.8682 9.96935 20.4927 10.4188 20.8137C10.8682 21.1347 11.4927 21.0307 11.8137 20.5812L13.5 18.2205L15.1863 20.5812C15.5073 21.0307 16.1318 21.1347 16.5812 20.8137C17.0307 20.4927 17.1347 19.8682 16.8137 19.4188L14.7289 16.5L16.8137 13.5812C17.1347 13.1318 17.0307 12.5073 16.5812 12.1863C16.1318 11.8653 15.5073 11.9693 15.1863 12.4188L13.5 14.7795L11.8137 12.4188Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                    <a href='##'>
                        + Ajouter un rendez-vous
                    </a>
                </div>
            </div>
            <br/>
            <div className='appointment_content'>
                <div className='card-sth'>
                    <div className='card-sth-title'>
                        <h3>
                            Nouveaux rendez-vous non lu
                        </h3>
                    </div>

                    <div className='card-sth-list'>
                        <div>
                            {
                                appointmentNotViewList.map((appointment, index)=>(
                                    <div className='card-sth-list-content'>
                                        <div className='card-sth-list-content-title wait'>
                                            {appointment.date_rdv}
                                        </div>
                                        <div className='card-sth-list-content-body'>
                                            {
                                                // appointment.list.map((event, index)=>(
                                                    <div className='card-sth-list-elt-container'>
                                                        <div className='card-sth-list-elt-container-left'></div>
                                                        <div className='card-sth-list-elt' key={appointment.nom+index}
                                                            // style={{
                                                            //     background: event.timeIsUp ? "#00000011" : "#ffffff",
                                                            // }}
                                                        >
                                                            <div className='card-sth-list-elt-name'>
                                                                {appointment.nom} {appointment.prenom}
                                                            </div>
                                                            <div  className='card-sth-list-elt-description'>
                                                                {appointment.motif_consultation}
                                                            </div>
                                                            <div className='card-sth-list-elt-dateTime'
                                                                // style={{
                                                                //     color: event.timeIsUp ? "red" : "#00000088",
                                                                // }}
                                                            >
                                                                <div className='card-sth-list-elt-dateTime-date'>
                                                                    {appointment.date_rdv}
                                                                </div>
                                                                <div className='card-sth-list-elt-dateTime-time'>
                                                                    {appointment.heure_debut}
                                                                </div>
                                                            </div>
                                                            <div className='card-sth-list-elt-button'>
                                                                <button className='valid'
                                                                    onClick={(e) => {
                                                                        setUpdateType(1);
                                                                        setCurrentAction(appointment);
                                                                    }}
                                                                >
                                                                    Confirmer
                                                                </button>
                                                                <button className='cancel'
                                                                    onClick={(e) => {
                                                                        setUpdateType(2);
                                                                        setCurrentAction(appointment);
                                                                    }}
                                                                >
                                                                    refuser
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                // ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='card-sth'>
                    <div className='card-sth-title'>
                        <h3>
                            Nouveaux rendez-vous lu
                        </h3>
                    </div>

                    <div className='card-sth-list'>
                        <div>
                            {
                                appointmentViewList.map((appointment, index)=>(
                                    <div className='card-sth-list-content'>
                                        <div className='card-sth-list-content-title wait'>
                                            {appointment.date_rdv}
                                        </div>
                                        <div className='card-sth-list-content-body'>
                                            {
                                                // appointment.list.map((event, index)=>(
                                                    <div className='card-sth-list-elt-container'>
                                                        <div className='card-sth-list-elt-container-left'></div>
                                                        <div className='card-sth-list-elt' key={appointment.nom+index}
                                                            // style={{
                                                            //     background: event.timeIsUp ? "#00000011" : "#ffffff",
                                                            // }}
                                                        >
                                                            <div className='card-sth-list-elt-name'>
                                                                {appointment.nom} {appointment.prenom}
                                                            </div>
                                                            <div  className='card-sth-list-elt-description'>
                                                                {appointment.motif_consultation}
                                                            </div>
                                                            <div className='card-sth-list-elt-dateTime'
                                                                // style={{
                                                                //     color: event.timeIsUp ? "red" : "#00000088",
                                                                // }}
                                                            >
                                                                <div className='card-sth-list-elt-dateTime-date'>
                                                                    {appointment.date_rdv}
                                                                </div>
                                                                <div className='card-sth-list-elt-dateTime-time'>
                                                                    {appointment.heure_debut}
                                                                </div>
                                                            </div>
                                                            <div className='card-sth-list-elt-button'>
                                                                <button className='valid'
                                                                    onClick={(e) => {
                                                                        setUpdateType(1);
                                                                        setCurrentAction(appointment);
                                                                    }}
                                                                >
                                                                    Confirmer
                                                                </button>
                                                                <button className='cancel'
                                                                    onClick={(e) => {
                                                                        setUpdateType(2);
                                                                        setCurrentAction(appointment);
                                                                    }}
                                                                >
                                                                    refuser
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                // ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='card-sth sth-valid'>
                    <div className='card-sth-title'>
                        <h3>
                            Rendez-vous acceptés
                        </h3>
                    </div>
                    <div className='card-sth-list'>
                        <div>
                        {
                                currentUser.confirmList.map((appointment, index)=>(
                                    <div className='card-sth-list-content'>
                                        <div className='card-sth-list-content-title wait'>
                                            {appointment.date_rdv}
                                        </div>
                                        <div className='card-sth-list-content-body'>
                                            {
                                                // appointment.list.map((event, index)=>(
                                                    <div className='card-sth-list-elt-container'>
                                                        <div className='card-sth-list-elt-container-left'></div>
                                                        <div className='card-sth-list-elt' key={appointment.nom+index}
                                                            // style={{
                                                            //     background: event.timeIsUp ? "#00000011" : "#ffffff",
                                                            // }}
                                                        >
                                                            <div className='card-sth-list-elt-name'>
                                                                {appointment.nom} {appointment.prenom}
                                                            </div>
                                                            <div  className='card-sth-list-elt-description'>
                                                                {appointment.motif_consultation}
                                                            </div>
                                                            <div className='card-sth-list-elt-dateTime'
                                                                // style={{
                                                                //     color: event.timeIsUp ? "red" : "#00000088",
                                                                // }}
                                                            >
                                                                <div className='card-sth-list-elt-dateTime-date'>
                                                                    {appointment.date_rdv}
                                                                </div>
                                                                <div className='card-sth-list-elt-dateTime-time'>
                                                                    {appointment.heure_debut}
                                                                </div>
                                                            </div>
                                                            <div className='card-sth-list-elt-button'>
                                                                <button className='cancel'
                                                                    onClick={(e) => {
                                                                        setUpdateType(2);
                                                                        setCurrentAction(appointment);
                                                                    }}
                                                                >
                                                                    refuser
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                // ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='card-sth sth-cancel'>
                    <div className='card-sth-title'>
                        <h3>
                            Rendez-vous annulés
                        </h3>
                    </div>
                    <div className='card-sth-list'>
                        <div>
                        {
                                currentUser.refuseList.map((appointment, index)=>(
                                    <div className='card-sth-list-content'>
                                        <div className='card-sth-list-content-title wait'>
                                            {appointment.date_rdv}
                                        </div>
                                        <div className='card-sth-list-content-body'>
                                            {
                                                // appointment.list.map((event, index)=>(
                                                    <div className='card-sth-list-elt-container'>
                                                        <div className='card-sth-list-elt-container-left'></div>
                                                        <div className='card-sth-list-elt' key={appointment.nom+index}
                                                            // style={{
                                                            //     background: event.timeIsUp ? "#00000011" : "#ffffff",
                                                            // }}
                                                        >
                                                            <div className='card-sth-list-elt-name'>
                                                                {appointment.nom} {appointment.prenom}
                                                            </div>
                                                            <div  className='card-sth-list-elt-description'>
                                                                {appointment.motif_consultation}
                                                            </div>
                                                            <div className='card-sth-list-elt-dateTime'
                                                                // style={{
                                                                //     color: event.timeIsUp ? "red" : "#00000088",
                                                                // }}
                                                            >
                                                                <div className='card-sth-list-elt-dateTime-date'>
                                                                    {appointment.date_rdv}
                                                                </div>
                                                                <div className='card-sth-list-elt-dateTime-time'>
                                                                    {appointment.heure_debut}
                                                                </div>
                                                            </div>
                                                            <div className='card-sth-list-elt-button'>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                // ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                updateType !== null?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Afin de renforcer la sécurité de votre compte, nous devons vérifier que c'est bien vous.
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(updateType===1) {
                                        confirmRDV(currentAction);
                                    } else if( updateType===2) {
                                        refuseRDV(currentAction);
                                    }
                                }}
                            >
                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input required value={password} name='password-tel' type='password'
                                                onChange={(e)=>{
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            
        </div>
        {
            isLoading ?
                <div>
                    <div className='globalALert_back'></div>
                    <div className='globalALert'>
                        <div className='globalALert_content'>
                            <div className='globalALert_content_loader'>
                                <img src={loader} alt='freeJobs'/>
                            </div>
                        </div>
                    </div>
                </div>
            :
                null
        }
        </>
    );
}

const mapStateToProps = state => ({
    counter: state.counter, // Connectez la propriété counter du store à props
});

const mapDispatchToProps = dispatch => ({
    increment: () => dispatch({ type: 'INCREMENT' }), // Dispatch une action
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDoctorAppointment);